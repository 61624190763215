<template>
	<div class="render">
		<div v-if="!this.$store.getters['user/getUserFields'].is_admin">Вы не администратор, авторизируйтесь как администратор!</div>
		<template v-else>
		<div v-if="if_main" class="content_table">
			<div class="top">
				<h1>Пользователи</h1>
			</div>
			<FilterCustom @updateFilter="onUpdateFilter" :visible="{phone:true,nickname:true,maska:'###########',visible:true, managerVisible:true}"/>
			<Table
				:page_type="'users_adimin'"
				:items="action_items"
				:loading="loading"
				:perPage="perPage"
				:server="false"
				:hideselect="true"
				:callback_view="viewUser"
				@user_view="viewUser"
				:headers="users_table.headers"
				:data="users_table.filterData"
			/>
		</div>

		<div v-if="!if_main">
			<div class="top">
				<div @click="if_main = true" class="vback">
					Назад
				</div>
				<h1>Пользователь: {{ user_login }}</h1>

				<div @click="saveUser" class="cashup mt-0">Сохранить</div>
			</div>
			<div class="content">
				<div class="left">
					<div class="inform">
						<div class="balance w100p">
							<div class="title">
								Баланс
							</div>
							<div class="num">
								{{user_balance}} р.
							</div>
						</div>
						<div class="balance w100p">
							<div class="title">
								Минуты
							</div>
							<div class="num">
								{{user_minutes}} мин.
							</div>
						</div>
						<div class="balance">
							<div class="title">
								1 СМС
							</div>
							<div class="num">
								<v-text-field v-model="price_sms" placeholder="Стоимость" type="number"></v-text-field>
							</div>
						</div>
					</div>
					<div class="useradmn_info mt-6">
						<p>Кастомный тариф:</p>
						<div class="flex">
							<div class="balance mr-5">
								<div class="title">
									Сумма пополнения
								</div>
								<div class="num">
									<v-text-field v-model="customTariff.sum" placeholder="Стоимость тарифа" type="number"></v-text-field>
								</div>
							</div>
							<div class="balance mr-5">
								<div class="title">
									Минуты
								</div>
								<div class="num">
									<v-text-field v-model="customTariff.min" placeholder="Минуты" type="number"></v-text-field>
								</div>
							</div>
							<div class="balance">
								<div class="title">
									Стоимость 1 мин.
								</div>
								<div class="num">
									<v-text-field v-model="resPriceCustomTariff" placeholder="Цена минуты" type="number"></v-text-field>
								</div>
							</div>
						</div>
<!--						<div  class="cashup btn_custom_tariff mt-0">Провести тариф</div>-->
						<div class="custom_tariff_save_message">
<!--							<div @click="saveCustomTariffs" class="save mt-0 mr-0">Провести тариф</div>-->
							<div @click="securityOfAdminActions('customTariffMinutes')" class="save mt-0 mr-0">Провести тариф</div>
							<div v-if="statusCustomTariff === true" class="save_message">Тариф прошел успешно!</div>
							<div style="color: #DE4040" v-if="statusCustomTariff === false" class="save_message">Недостаточно баланса пользователя!</div>
							<div style="color: #DE4040" v-if="statusCustomTariff === 'noValid'" class="save_message">Не верное заполнение формы!</div>
						</div>
					</div>
					<div class="useradmn_info mt-6">
						<p>Пополнение баланса пользователя:</p>
						<div class="flex">
							<div class="mr-5 w100p">
								<div class="title">
									Коментарий к пополнению
								</div>
								<div class="num">
									<v-text-field v-model="addBalanceUser.comment" placeholder="Комментарий" type="text"></v-text-field>
								</div>
							</div>
							<div style="width: 275px;">
								<div class="title">
									Сумма пополнения
								</div>
								<div class="num">
									<v-text-field v-model="addBalanceUser.sum" placeholder="Сумма" type="number"></v-text-field>
								</div>
							</div>
						</div>
						<div class="custom_tariff_save_message">
							<div @click="securityOfAdminActions('addBalanceUser')" class="save mt-0 mr-0">Пополнить баланс</div>
							<div v-if="statusAddBalanceUser === true" class="save_message">Средства поступили на счет!</div>
							<div style="color: #DE4040" v-if="statusAddBalanceUser === false" class="save_message">Ошибка при пополнении!</div>
							<div style="color: #DE4040" v-if="statusAddBalanceUser === 'noValid'" class="save_message">Не верное заполнение формы!</div>
						</div>
					</div>
					<div class="useradmn_info mt-6">
						<p>Оплата кастомной услуги:</p>
						<div class="flex">
							<div class="mr-5 w100p">
								<div class="title">
									Название оплачиваемой услуги
								</div>
								<div class="num">
									<v-text-field v-model="customService.name" placeholder="Название тарифа" type="text"></v-text-field>
								</div>
							</div>
							<div class="">
								<div class="title">
									Стоимость
								</div>
								<div class="num">
									<v-text-field v-model="customService.price" placeholder="Цена услуги" type="number"></v-text-field>
								</div>
							</div>
						</div>
						<div class="custom_tariff_save_message">
							<div @click="securityOfAdminActions('customServices')" class="save mt-0 mr-0">Списание за услугу</div>
							<div v-if="statusCustomService === true" class="save_message">Средства за услугу списаны!</div>
							<div style="color: #DE4040" v-if="statusCustomService === false" class="save_message">Недостаточно баланса пользователя!</div>
							<div style="color: #DE4040" v-if="statusCustomService === 'noValid'" class="save_message">Не верное заполнение формы!</div>
						</div>
					</div>
					<div class="useradmn_info mt-7">
						<p>Оповестить этого пользователя:</p>
						<v-textarea
							class="my_textarea"
							name="input-7-1"
							auto-grow
							placeholder="Сообщение"
							v-model="massage_text"
						></v-textarea>
						<p v-show="massage_sended" style="color:green;">Сообщение отправлено</p>
						<p v-show="massage_novalid" style="color:red;">Нельзя отправить пустое сообщение</p>
						<div @click="sendUserMassage" class="save mt-0">Добавить оповещение</div>
					</div>
				</div>
				<div class="right">
					<p>Статус пользователя:</p>
					<v-select v-model="user_status" :items="user_statuses" item-value="id" item-text="name"
					></v-select>
          <v-checkbox v-model="active_client" label="Активный клиент"></v-checkbox>
					<p>Псевдоним пользователя</p>
					<v-text-field v-model="user_nickname" placeholder="Псевдоним" type="text"></v-text-field>
          <p>Имя менеджера</p>
          <v-text-field v-model="user_manager" placeholder="Имя" type="text"></v-text-field>
					<div class="useradmn_info mt-5">
						<p v-for="doc in user_docs" :key="doc.title">{{ doc.title }}:
							<b v-if="!doc.is_link">{{ doc.value }}</b>
							<a v-if="doc.is_link" target="_blank" :href="doc.value">{{ doc.value }}</a>
						</p>
					</div>
				</div>
			</div>
			<div class="bottom_admuser">
				<h2>Cписок транзакций</h2>
				<Table
					:page_type="'users_adimin_transactions'"
					:loading="false"
					:perPage="perPage"
					:server="false"
					:hideselect="true"
					:headers="users_payments_table.headers"
					:data="users_payments_table.data"
				/>
			</div>
			<div class="bottom_admuser">
				<h2>История покупок минут</h2>
				<Table
					:page_type="'users_adimin_transactions'"
					:loading="false"
					:perPage="perPage"
					:server="false"
					:hideselect="true"
					:headers="user_tariffs_table.headers"
					:data="user_tariffs_table.data"
				/>
			</div>
			<div class="bottom_admuser">
				<h2>История оплаченых услуг</h2>
				<Table
					:page_type="'users_adimin_transactions'"
					:loading="false"
					:perPage="perPage"
					:server="false"
					:hideselect="true"
					:headers="user_service_table.headers"
					:data="user_service_table.data"
				/>
			</div>
			<FrameModalPopup :dialogShow="verificationForm.popup" @close="verificationForm.popup = false">
				<div class="center">
					<div>
						<div class="block_center">
							<Icon name="warning"/>
						</div>
						<div class="popup_text">
							{{verificationForm.text}}
						</div>
					</div>
					<div class="my_btn add_modal" @click="requestCabinetUser()">Подтверждаю</div>
				</div>
			</FrameModalPopup>
		</div>
		</template>
	</div>
</template>
<script>

import Table from '../../components/Table/DataTable.vue'
import FilterCustom from '../../components/FilterMy.vue'
import FrameModalPopup from "@/components/Modal/FrameModalPopup";
import Icon from '@/components/SetIcon'
import moment from 'moment';


export default {
	components: {
		Table,
		FilterCustom,
		FrameModalPopup,
		Icon
	},
	data() {
		return {
			if_main: true,
			user_login: "",
			massage_text: "",
			massage_sended: false,
			massage_novalid: false,
			filters: {},
			loading: true,
			perPage: 10,
			tablist: "all",
			edit_popup: false,
			action_items: [
				{title: 'Редактировать', action: 'user_view'},
			],
			users_table: {
				data: [],
				filterData: [],
				headers: [
					{text: 'Телефон', value: 'phone'},
					{text: 'Псевдоним', value: 'nickname'},
					{text: 'Ответственный', value: 'manager'},
					{text: 'Статус', value: 'user_status'},
					{text:'Последняя авторизация',value: 'last_auth'},
					{text: 'Дата регистрации', value: 'created_at'},
					{text: 'Баланс', value: 'balance'},
					{text: 'Минуты', value: 'minutes'},
					{text: 'Действия', value: 'actions', sortable: false}
				]
			},
			users_payments_table: {
				headers: [
					{text: '№', align: 'start', value: 'id'},
					{text: 'Тип операции', value: 'type'},
					{text: 'Сумма', value: 'sum'},
					{text: 'Дата обзвона', value: 'date'},
					{text: 'Статус', value: 'status'}
				],
				data: this.$store.getters['user/getUserFields'].payments,
			},
			user_tariffs_table: {
				headers: [
					{text: '№', align: 'start', value: 'id'},
					{text: 'Стоимость', value: 'price'},
					{text: 'Куплено минут', value: 'minutes'},
					{text: 'Дата покупки', value: 'created_at'}
				],
				data: []
			},
			user_service_table: {
				headers: [
					{text: '№', align: 'start', value: 'id'},
					{text: 'Предоставленная услуга', value: 'service'},
					{text: 'Стоимость', value: 'price'},
					{text: 'Дата оплаты', value: 'created_at'}
				],
				data: []
			},
			user_statuses: [
				{id: "unactive", name: "Не активирован"},
				{id: "waiting", name: "На проверке"},
				{id: "active", name: "Активен"},
			],
			user_status: "active",
			price_ivr: 1,
			price_sms: 1,
			user_balance: 0,
			user_minutes: 0,
			user_docs: [],
			user_nickname: '',
			user_manager:'',
			active_client:true,
			filters_data_users: false,
			customTariff: {
				sum: '',
				min: '',
			},
			statusCustomTariff: '',
			customService: {
				name: '',
				price: ''
			},
			statusCustomService: '',
			addBalanceUser: {
				sum: '',
				comment: ''
			},
			statusAddBalanceUser: '',
			verificationForm: {
				text: '',
				caseVerification: '',
				popup: false
			}
		}
	},
	mounted() {
		this.render()
	},
	watch: {
		user_login() {
			this.customTariff.sum = ''
			this.customTariff.min = ''
			this.statusCustomTariff = ''
			this.customService.name = ''
			this.customService.price = ''
			this.statusCustomService = ''
			this.addBalanceUser.sum = ''
			this.addBalanceUser.comment = ''
			this.statusAddBalanceUser = ''
		}
	},
	computed:{
		resPriceCustomTariff(){
			return +(this.customTariff.sum / this.customTariff.min).toFixed(2)
		},
	},
	methods: {
		render() {
			this.$http.post("/admin_users/list",
				{}, (result) => {
					this.users_table.data = result.map(item => {
						console.log('🚀 -> render -> item:', moment(item.created_at).format('YYYY-MM-DD hh:mm:ss'))
						// let date = new Date(item.created_at) 
            
						item.created_at =  moment(item.created_at).format('YYYY-MM-DD hh:mm:ss')
						return item
					})
					this.loading = false
					this.filterDataUsers()
				})
		},
		sendUserMassage() {
			if (this.massage_text) {
				this.massage_novalid = false
				this.$http.post("/admin_users/send_massage",
					{
						login: this.user_login,
						text: this.massage_text,
					}, (result) => {
						if (result) {
							this.massage_text = ""
							this.massage_sended = true
						} else {
							alert("Упс! Что-то пошло не так")
						}

					})
			} else {
				this.massage_novalid = true
			}

		},
		saveUser() {
			this.$http.post("/admin_users/save",
				{
					login: this.user_login,
					user_status: this.user_status,
					price_ivr: this.price_ivr,
					price_sms: this.price_sms,
					nickname: this.user_nickname,
          manager: this.user_manager,
          admin_visible: this.active_client,
				}, (result) => {
					if (result) {
						this.if_main = true
						this.render()
					}
				})
		},
		viewUser(item) {
			let login = item.phone
			this.$http.post("/admin_users/getone",
				{
					login: login
				}, (result) => {
					// console.log(result, '=======================================')
					this.users_payments_table.data = result.payments.map(item => {
						const date = new Date(item.date)
						item.date = `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth()+1).toString().padStart(2, '0')}.${date.getFullYear()}`
						return item
					})
					this.user_balance = result.balance;
					this.user_minutes = result.minutes;
					this.price_ivr = result.price_ivr
					this.price_sms = result.price_sms
					this.user_status = result.user_status
					this.user_login = result.login
					this.user_nickname = result.nickname
          this.user_manager = result.manager
          this.active_client = result.admin_visible
					let user_docs_translete = {
  type_user: "Тип контрагента",
  type_organization: "Форма организации",
  company_name: "Название компании",
  company_inn: "ОГРН",
  company_inn_file: "Cвидетельство ИНН/ОГРН",
  company_solution: "Приказ или решение о назначении",
  company_order: "Приказ о назначении ген. дир.",
  company_file: "Карточка компании",
  pasport_one: "Первая страница паспорта",
  pasport_two: "Вторая страница паспорта",
  photo_passport_person: "Фото паспорта в руках",
  email: "Email",

  // Новые:
  inn: "ИНН",
  kpp: "КПП",
  ogrn: "ОГРН ООО",
	ogrnip: "ОГРН ИП",
  r_s: "Расчётный счёт",
  kor_s: "Корр. счёт",
  bik: "БИК",
  bank: "Банк",
  address: "Юрид. адрес",
};
					this.user_docs = []
					for (let e in result.user_docs) {
						this.user_docs.push({
							title: user_docs_translete[e],
							value: result.user_docs[e],
							is_link: e === "company_file"
								|| e === "pasport_one"
								|| e === "pasport_two"
								|| e === 'company_inn_file'
								|| e === 'company_solution'
								|| e === 'company_order'
								|| e === 'photo_passport_person'
						})
					}
				})

			this.if_main = false

			this.$http.post("/admin_users/get_table_service",
				{
					login: login
				}, (result) => {
					result.map((item, inx) => item.id = inx + 1)
					this.user_service_table.data = result.map(item => {
						const date = new Date(item.created_at)
						item.created_at = `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth()+1).toString().padStart(2, '0')}.${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`
						return item
					})
				})
			this.$http.post("/admin_users/get_table_tarifs",
				{
					login: login
				}, (result) => {
					result.map((item, inx) => item.id = inx + 1)
					this.user_tariffs_table.data = result.map(item => {
						const date = new Date(item.created_at)
						item.created_at = `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth()+1).toString().padStart(2, '0')}.${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`
						return item
					})
				})


		},
		onUpdateFilter(new_filter) {
			this.filters_data_users = new_filter

			this.filterDataUsers()
		},
		filterDataUsers() {
			let newData = this.users_table.data
			if(this.filters_data_users.search){
				newData = newData.filter(user => user.phone.includes(this.filters_data_users.search))
			}
			if(this.filters_data_users.searchNickname){
				newData = newData.filter(user => {
					if(user.nickname) {
						return user.nickname.includes(this.filters_data_users.searchNickname)
					} else { return false }
				})
			}

      if(this.filters_data_users.searchManager){
        newData = newData.filter(user => {
          if(user.manager) {
            return user.manager.includes(this.filters_data_users.searchManager)
          } else { return false }
        })
      }
      if(this.filters_data_users.active_client){
        newData = newData.filter(user => user.admin_visible)
      }
			// console.log(newData)
			this.users_table.filterData = newData
		},
		securityOfAdminActions(way) {
			this.verificationForm.text = ''
			this.verificationForm.caseVerification = ''
			switch (way) {
				case 'customTariffMinutes':
					if (this.customTariff.sum && this.customTariff.min) {
						this.statusCustomTariff = ''
						this.verificationForm.text = `Вы подтверждаете что хотите
						провести тариф пользователю ${this.user_login}.
						Ему будет начислено ${this.customTariff.min} минут и списано ${this.customTariff.sum} руб.
						После чего на счету пользователя будет ${+this.customTariff.min + +this.user_minutes} минут и
						${+this.user_balance - +this.customTariff.sum} руб.`
						this.verificationForm.caseVerification = 'customTariffMinutes'
						this.verificationForm.popup = true
					} else {
						this.statusCustomTariff = 'noValid'
					}
					break;
				case 'addBalanceUser':
					if (this.addBalanceUser.sum && this.addBalanceUser.comment) {
						this.statusAddBalanceUser = ''
						this.verificationForm.text = `Вы подтверждаете что хотите
						пополнить баланс пользователю ${this.user_login},
						на сумму ${this.addBalanceUser.sum} руб. с комментарием к пополнению "${this.addBalanceUser.comment}".
						После пополнения баланс пользователя составит ${+this.addBalanceUser.sum + +this.user_balance} руб.`
						this.verificationForm.caseVerification = 'addBalanceUser'
						this.verificationForm.popup = true
					} else {
						this.statusAddBalanceUser = 'noValid'
					}
					break;
				case 'customServices':
					if (this.customService.name && this.customService.price) {
						this.statusCustomService = ''
						this.verificationForm.text = `Вы подтверждаете что хотите
						списать средства с пользователя ${this.user_login},
						за услугу "${this.customService.name}" на сумму ${this.customService.price} руб.
						После чего на балансе клиента останется ${+this.user_balance - +this.customService.price} руб.`
						this.verificationForm.caseVerification = 'customServices'
						this.verificationForm.popup = true
					} else {
						this.statusCustomService = 'noValid'
					}
					break;
			}
		},
		requestCabinetUser(){
			this.verificationForm.popup = false
			switch (this.verificationForm.caseVerification) {
				case 'customTariffMinutes':
					if (this.customTariff.sum && this.customTariff.min) {
						this.$http.post("/admin_users/add_custom_tarif",
							{
								sum: this.customTariff.sum,
								min: this.customTariff.min,
								login: this.user_login
							}, (result) => {
								this.user_balance = +this.user_balance - +this.customTariff.sum;
								this.user_minutes = +this.user_minutes + +this.customTariff.min;
								this.statusCustomTariff = result
								this.customTariff.sum = ''
								this.customTariff.min = ''
								//this.viewUser({phone: this.user_login})
							})
					}
					break;
				case 'addBalanceUser':
					if (this.addBalanceUser.sum && this.addBalanceUser.comment) {
						this.$http.post("/admin_users/add_user_balance",
							{
								sum: this.addBalanceUser.sum,
								comment: this.addBalanceUser.comment,
								login: this.user_login
							}, (result) => {
								this.user_balance = +this.user_balance + +this.addBalanceUser.sum;
								this.statusAddBalanceUser = result
								this.addBalanceUser.sum = ''
								this.addBalanceUser.comment = ''
								//this.viewUser({phone: this.user_login})
							})
					}
					break;
				case 'customServices':
					if (this.customService.name && this.customService.price) {
						this.$http.post("/admin_users/add_user_service",
							{
								name: this.customService.name,
								price: this.customService.price,
								login: this.user_login
							}, (result) => {
								this.user_balance = +this.user_balance - +this.customService.price;
								this.statusCustomService = result
								this.customService.name = ''
								this.customService.price = ''
								//this.viewUser({phone: this.user_login})
							})
					}
					break;
			}
		}
	},
}
</script>
<style scoped>
p {
	text-align: left;
}
.custom_tariff_save_message {
	display: flex;
}
.block_center {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}
.save_message {
	height: 41px;
	margin: 0 0 0 35px;
	color: #1caf1c;
	padding: 8px;
}

.useradmn_info {
	background: #FFFFFF;
	padding: 24px;
	box-shadow: 0px 0px 2px rgb(0 0 0 / 6%), 0px 8px 24px rgb(0 0 0 / 10%);
	border-radius: 16px;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
}

.w100p {
    width: 100%;
}

.popup_text {
	text-align: center;
    margin-bottom: 25px;
}

.v_email_updated_massage {
	text-align: left;
	color: green;
}

.v_err_updated_massage {
	text-align: left;
	color: #b01111;
}

.mb26 {
	margin-bottom: 26px;
}

.top {
	margin-bottom: 24px;
}

.history_title {
	font-style: normal;
	font-weight: 800;
	font-size: 28px;
	line-height: 130%;
	color: #404247;
	text-align: left;
	margin-bottom: 8px;
}

.desc {
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 150%;
	color: #55575C;
	text-align: left;
	margin-bottom: 16px;
}

.ok {
	margin-top: 30px;
	background: #E8F7F4;
	width: 100%;
	border-radius: 8px;
	padding: 16px 24px;
}

.cashup {
	font-style: normal;
	font-weight: 600;
	font-size: 17px;
	line-height: 120%;
	color: #2B2D33;
	background: #FEE600;
	border-radius: 8px;
	padding: 13px;
	margin-top: 40px;
	cursor: pointer;
}

.btn_custom_tariff {
	width: max-content;
	margin-left: auto;
}

.history {
	margin: 8px auto 0 auto;
	font-style: normal;
	font-weight: 500;
	font-size: 17px;
	line-height: 120%;
	color: #00809C;
	cursor: pointer;
	position: relative;
	width: max-content;
}

.history:after {
	content: '';
	display: block;
	height: 1px;
	width: 100%;
	background: #00809C;
	position: absolute;
	bottom: -4px;
}

.save {
	background: #FFFFFF;
	border: 1px solid #FEE600;
	border-radius: 8px;
	padding: 9px 20px;
	font-style: normal;
	font-weight: 600;
	font-size: 15px;
	margin-top: 16px;
	line-height: 110%;
	color: #2B2D33;
	max-width: max-content;
	cursor: pointer;
}

.top {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
}

.inform {
	display: flex;
	background: #FFFFFF;
	padding: 24px;
	box-shadow: 0 0 2px rgba(0, 0, 0, 0.06), 0 8px 24px rgba(0, 0, 0, 0.1);
	border-radius: 16px;
	justify-content: space-between;
}

.left {

	width: 50%;
	height: max-content;
}

.content {
	display: flex;
}

.right {
	width: 50%;
	padding-left: 32px;
}

h1 {
	margin-bottom: 24px;
	font-style: normal;
	font-weight: 800;
	font-size: 28px;
	line-height: 130%;
	color: #404247;
}

h2 {
	font-style: normal;
	font-weight: 800;
	font-size: 24px;
	line-height: 130%;
	color: #404247;
	text-align: left;
	margin-top: 32px;
	margin-bottom: 24px;
}

.label {
	font-style: normal;
	font-weight: 600;
	font-size: 15px;
	line-height: 120%;
	color: #404247;
	text-align: left;
	margin-bottom: 6px;
}

.balance {
	width: 40%;
}

.left .title {
	font-style: normal;
	font-weight: 500;
	font-size: 17px;
	line-height: 120%;
	color: #55575C;
	text-align: left;
    margin-bottom: 4px;
}

.left .num {
	font-style: normal;
	font-weight: 800;
	font-size: 36px;
	line-height: 130%;
	color: #404247;
	text-align: left;
}

.alert {
	background: #FEF3EC;
	padding: 16px 24px;
	border-radius: 8px;
	display: flex;
	justify-content: space-between;
	width: 100%;

}

.left_text {
	display: flex;
	align-items: center;
}

.left_text .text {
	margin-left: 18px;
}

.mmm_data {
	background: #FFFFFF;
	border: 1px solid #FEE600;
	border-radius: 8px;
	font-style: normal;
	font-weight: 600;
	font-size: 15px;
	line-height: 110%;
	text-align: center;
	color: #2B2D33;
	padding: 7px 17px;
	cursor: pointer;
}

.vback {
	border: 1px solid #c3c4c6;
	padding: 10px;
	border-radius: 10px;
}

.vback:hover {
	cursor: pointer;
}

.ok .text {
	color: #008E7D;
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 120%;
}
</style>
