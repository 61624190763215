<template>
  <div class="wrapper" id="wrapper">
    <img class="logo" src="../assets/img/CRMСalls.svg" alt="logo">
    <div class="login_form" v-bind:class="{ disable: axios_wait }">
      <h1 class="title">{{params.title}}</h1>
      <div class="danger" v-if="isDanger">{{dangerMessage}}</div>
      <div v-if="params.text" class="text">{{params.text}}</div>
      <div v-for="(item, i) of params.components" :key="i">
        <component :is="item.name" :color="item.color" :text="item.text" :callback="item.callback" :phoner="item.phone" :phoneLand="phoneLand"  />
      </div>
    </div>
    <LoginFooter/>
  </div>
</template>
<script>
import LoginInputNumber from '../components/Login/LoginInputNumber'
import LoginInputCode from '../components/Login/LoginInputCode'
import PasswordInput from '../components/Login/PasswordInput'
import ButtonLink from '../components/Login/ButtonLink'
import ButtonBig from '../components/Login/ButtonBig'
import LoginOr from '../components/Login/LoginOr'
import LoginFooter from '../components/Login/LoginFooter'
import PolicyText from '../components/Login/PolicyText'
import BackTimer from '../components/Login/BackTimer'
import YandexCapctha from '../components/Login/yandexCaptcha.vue'
import TextLabel from '../components/TextLabel'
import Nickname from '../components/Login/NickName'


/* global ym */
export default {
  props: ['logined'],
  data(){
    return {
      phoneLand:'',
      nickname:'',
      params: {},
      phone: '',
      cod:'',
      tokenCaptcha: null,
      axios_wait:false,
      isDanger:false,
      dangerMessage:'',
      password: '',
      steps: {
        'start': {
          title: 'Вход в систему',
          text: '',
          components:[
            {name: 'LoginInputNumber', phoner: this.phone,callback:this.phone_change},
            
            {name: 'PasswordInput', state: this.pass_state,callback:this.password_change},
            {name: 'ButtonLink', text: 'Восстановить пароль',callback:this.recover1, align: 'left', action: 'recover1'},
            {name: 'ButtonBig', text: 'Войти', color: 'yellow', callback: this.login},
            {name: 'LoginOr', text: 'Или'},
            {name: 'ButtonBig', text: 'Зарегистрироваться', color: 'white', action: 'reg1',callback: this.register},
            {name: 'ButtonLink', text: 'Войти по смс', align: 'center', action: 'sms1',callback: this.sms1}
          ]
        },
        'reg1': {
          title: 'Регистрация',
          text: 'Введите номер телефона',
          components: [
            {name: 'LoginInputNumber',callback:this.phone_change,isPhone:'refphone'},
            {name: 'Nickname', callback:this.nickname_change},
            {name: 'YandexCapctha', callback: this.getTokenCaptcha },
            {name: 'PolicyText'},
            {name: 'ButtonBig', text: 'Получить код', color: 'yellow', callback: this.register_cod },
            {name: 'LoginOr', text: 'Уже есть аккаунт?'},
            {name: 'ButtonBig', text: 'Войти', color: 'white', callback: this.login_form}
          ]
        },
        'reg2': {
          title: 'Введите код',
          text: '',
          components: [
            { name: 'TextLabel', text: 'Если смс с кодом не приходит, проверьте папку Спам' },
            {name: 'ButtonLink', text: 'Изменить номер', callback: this.register},
            {name: 'LoginInputCode',callback:this.register_complete},
            {name: 'BackTimer',callback:this.register_cod},
            {name: 'LoginOr', text: 'Уже есть аккаунт?'},
            {name: 'ButtonBig', text: 'Войти', color: 'white', callback: this.login_form}
          ]
        },
        'reg3': {
          title: 'Придумайте пароль',
          text: 'Это нужно для вашей безопасности и удобства. Вы сможете сохранить данные и входить в аккаунт автоматически',
          components: [
            {name: 'PasswordInput', state: this.pass_state,callback:this.password_change},
            {name: 'ButtonBig', text: 'Готово', color: 'white', callback: this.register_password}
          ]
        },
        'sms1': {
          title: 'Вход по смс',
          text: 'Введите номер телефона',
          components: [
            {name: 'LoginInputNumber',callback:this.phone_change},
            {name: 'ButtonBig',callback:this.login_sms1, text: 'Получить код', color: 'yellow'},
            {name: 'ButtonLink', text: 'Войти по паролю', align: 'center', action: 'start', callback: this.login_form},
            {name: 'LoginOr', text: 'Нет аккаунта?'},
            {name: 'ButtonBig', text: 'Зарегистрироваться', color: 'white', action: 'reg1', callback: this.register},
          ]
        },
        'sms2': {
          title: 'Введите код',
          text: 'Мы отправили код подтверждения на номер ' + this.phone,
          components: [
            {name: 'ButtonLink', text: 'Изменить номер', align: 'center', action: 'sms1',callback: this.sms1},
            {name: 'LoginInputCode',callback:this.login_cod},
            {name: 'BackTimer',callback:this.login_sms1}
          ]
        },
        'recover_cod': {
          title: 'Введите код',
          text: 'Мы отправили код подтверждения на номер ' + this.phone,
          components: [
            {name: 'ButtonLink', text: 'Изменить номер', align: 'center', callback: this.recover1},
            {name: 'LoginInputCode',callback:this.recover_password},
            {name: 'BackTimer',callback:this.recover_sms}
          ]
        },
        'recover1': {
          title: 'Восстановление пароля',
          text: 'Введите номер телефона',
          components: [
            {name: 'LoginInputNumber',callback:this.phone_change},
            {name: 'ButtonBig', text: 'Получить код', color: 'yellow',callback:this.recover_sms},
            {name: 'LoginOr', text: 'Нет аккаунта?'},
            {name: 'ButtonBig', text: 'Зарегистрироваться', color: 'white', action: 'reg1', callback: this.register},
          ]
        },
        'recover2': {
          title: 'Придумайте новый пароль',
          text: '',
          components: [
            {name: 'PasswordInput', state: this.pass_state,callback:this.password_change},
            {name: 'ButtonBig', text: 'Готово', color: 'white', callback: this.recover_complete}
          ]
        }
      }
    }
  },
  components: {
    LoginInputNumber, PasswordInput, ButtonLink, ButtonBig, LoginOr, LoginFooter, LoginInputCode, PolicyText, BackTimer, YandexCapctha, TextLabel,Nickname

  },
  methods:{
    getTokenCaptcha (data) {
      this.tokenCaptcha = data
    },
    recover_complete:function()
    {
      if (!this.axios_wait)
      {
      this.isDanger=false
      if (this.password.length>6)
      {
        this.axios_wait=true
        this.$http.post("/auth/recover",
        {
          login:this.phone,
          cod:this.cod,
          password:this.password
        },
        (data)=>{
          this.axios_wait=false
          if (data=="complete")
          {
            this.updatePage('start')
          }
        })
      }
      }
    },
    recover1:function()
    {
      this.updatePage('recover1')
    },
    nickname_change:function(nickname)
    {
      this.nickname = nickname
      
    },
    phone_change:function(phone)
    {
      this.phone=phone
    },
    password_change:function(password)
    {
      this.password=password
    },
    sms1:function()
    {
      this.updatePage('sms1')
    },
    recover_password:function(cod)
    {
      if (!this.axios_wait)
      {
      this.isDanger=false
      this.cod=cod
      this.axios_wait=true
      this.$http.post("/auth/recover",
      {
        login:this.phone,
        cod:this.cod
      },
      (data)=>{
        this.axios_wait=false
        if (data=="password_step")
        {
          this.updatePage('recover2')
        }
        else
        {
          this.isDanger=true
          this.dangerMessage="Код "+this.cod+" не верный"
        }
      })
      }
    },
    recover_sms:function()
    {
      if (this.phone === '+7 927 006-30-03') return
      if (!this.axios_wait)
      {
      this.axios_wait=true
      if (this.phone.length>15)
      {
      this.$http.post("/auth/recover",
      {
        login:this.phone
      },
      (data)=>{
        this.axios_wait=false
        if (data=="cod_step")
        {
          this.updatePage('recover_cod')
          this.params.text='Мы отправили код подтверждения на номер ' + this.phone
        }
        if (data=="no_phone")
        {
          this.isDanger=true
          this.dangerMessage="Нет такого номера"
        }

      })
       }
      }
    },
    login_cod:function(cod)
    {
      if (!this.axios_wait)
      {
      this.isDanger=false
      this.cod=cod
      this.axios_wait=true
      this.$http.post("/auth/loginsms",
      {
        login:this.phone,
        cod:this.cod
      },
      (data)=>{
        this.axios_wait=false

        if (data=="wrong")
        {
          this.isDanger=true
          this.dangerMessage="Код "+this.cod+" не верный"
        }
        else
        {
          localStorage.token=data
          this.$http.post("/auth/token",
          {
          token:localStorage.token
          },
          (data)=>{
            if (data)
            {
              if (data.user_data.is_admin)
              {
                this.$router.push({path: "/users"});
              }
              else
              {
                this.$router.push({path: "/"});
              }
            this.$store.commit('user/loginUser', data)
            }
          })
        }
      })
      }
    },
    login_sms1:function()
    {
      if(this.phone === '+7 927 006-30-03')  return  


      if (!this.axios_wait)
      {
      this.axios_wait=true
      if (this.phone.length>15)
      {
      this.$http.post("/auth/loginsms",
      {
        login:this.phone
      },
      (data)=>{
        this.axios_wait=false
        if (data=="cod_step")
        {
          this.updatePage('sms2')
          this.params.text='Мы отправили код подтверждения на номер ' + this.phone
        }
        if (data=="no_phone")
        {
          this.isDanger=true
          this.dangerMessage="Нет такого номера"
        }

      })
       }
      }
    },
    login:function()
    {
      if (!this.axios_wait)
      {


      if (this.phone.length>15 && this.password.length>6)
      {
        this.axios_wait=true
        this.$http.post("/auth/login",
        {
        login:this.phone,
        pass:this.password
        },
        (data)=>{
          this.axios_wait=false
          if (!data)
          {
          this.isDanger=true
          this.dangerMessage="Логин или пароль не верные"
          }
          else
          {
            if (data.user_data.is_admin)
            {
              this.$router.push({path: "/users"});
            }
            else
            {
              this.$router.push({path: "/"});
            }
            this.$store.commit('user/loginUser', data)
          }
        }
        )
      }

      }

    },
    click:function()
    {
      this.$http.test()
      alert("Привет лол")
    },
    login_form:function()
    {
      this.updatePage('start')
    },
    leads_tech_pb:  function(id){
      let phone = this.phone.replace(/[^0-9]/g,"");
      // let leads_tech_response = await axios.get(`https://offers.leads.tech/add-conversion/?click_id=${id}&goal_id=2&status=1&transaction_id=${phone}&sumConfirm=200`)
      this.$http.post("/system/lt_postback",
      {
        id:id,
        phone:phone
      },
      (data)=>{
        console.log(data)
      })



    },
    register_password:async function()
    {
      
      if (!this.axios_wait)
      {
      this.isDanger=false
      if (this.password.length>6)
      {
        this.axios_wait=true
        // await fetch('https://gate.crmcalls.ru/api/amo-crmcalls/send_data_amo', {
        //     method: 'POST',
        //     headers: {
        //       'Content-Type': 'application/json;charset=utf-8'
        //     },
        //     body: JSON.stringify({ phone: this.phone, isReg: true })
        //   })
          const searchParams = window.location.search;
          console.log(searchParams,'dfdfsdf')
          this.$http.post("/amo-crmcalls/send_data_amo",{
            phone: this.phone, isReg: true,searchParams:searchParams
          },()=>{
            
          })
        this.$http.post("/auth/register",
        {
          login:this.phone,
          cod:this.cod,
          password:this.password,
          nickname:this.nickname,
          token: sessionStorage.getItem('captchaToken')
        },
         (data)=>{
          this.axios_wait=false
          if (data=="complete")
          {
            
            
            if(this.$route.query.clickid){
              this.leads_tech_pb(this.$route.query.clickid)
            }

            this.updatePage('start')
          }
        })
      }
      }
      
      
    },
    register:function()
    {
      this.isDanger=false
      this.updatePage('reg1')
    },
    register_complete:function(cod)
    {
      
      if (!this.axios_wait)
      {
      this.isDanger=false
      this.cod=cod
      this.axios_wait=true
      this.$http.post("/auth/register",
      {
        login:this.phone,
        cod:this.cod,
        token: sessionStorage.getItem('captchaToken')
      },
      (data)=>{
        this.axios_wait=false
        if (data=="password_step")
        {
          this.updatePage('reg3')
        }
        else
        {
          this.isDanger=true
          this.dangerMessage="Код "+this.cod+" не верный"
        }
      })
      }

    },
    register_cod: function () {
      let checked_files = this.$store.getters[ 'user/getPolicy' ]
      if (checked_files) {
        if (!this.axios_wait) {
          this.isDanger = false
          if (this.phone.length > 15) {
            this.axios_wait = true
            
            
            this.$http.post("/auth/register",
              {
                login: this.phone,
                token: sessionStorage.getItem('captchaToken')
              },
              (data) => {
                
                this.axios_wait = false

                if (data == "code_step") {
                  this.updatePage('reg2')
                  this.params.text = 'Мы отправили код подтверждения на номер ' + this.phone

                }
                else {
                  if (data == "already_exists") {
                    this.isDanger = true
                    this.dangerMessage = "Данный телефон уже зарегистрирован"
                    this.updatePage('reg1')
                  }
                  else if(data === 'no captcha'){
                    this.isDanger = true
                    this.dangerMessage = "Не пройдена проверка на робота"
                    this.updatePage('reg1')
                  }
                  else {
                    this.updatePage('reg2')
                    this.params.text = 'Смс уже было выслано на номер ' + this.phone          
                  }

                }


              })


          }
        }
      } else {
        this.isDanger = true
        this.dangerMessage = "Необходимо подтвердить все соглашения"

      }


    },
    register_sms:function()
    {

      this.updatePage('reg3')
    },
    updatePage: function(nextStep = "start") {
      for (let prop in this.steps) {
        if (prop === nextStep) {
          this.params = this.steps[prop];
          if (nextStep === 'reg1') {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({ event: 'registration_field_number' });
            ym(90339826, 'reachGoal', 'registration_field_number2');
          }
        }
      }
    },
    // inversion: function(){
    //   this.logined = !this.logined
    // }
  },
  beforeMount(){
    this.updatePage('start')
  },
  mounted(){
    const searchParams = window.location.search;
    const match = window.location.search.match(/[?&]phone=([^&]*)/);
    if (match) {
    let phoneValue = match[1];
    this.$http.post("/auth/tenMinRoute",
      {phoneValue,searchParams},
      ()=>{

      })
    phoneValue = phoneValue.replace(/\D/g, '');
    phoneValue = `+7 ${phoneValue.slice(1, 4)} ${phoneValue.slice(4, 7)}-${phoneValue.slice(7, 9)}-${phoneValue.slice(9)}`;
    
    this.phoneLand = phoneValue;
    this.phone = phoneValue;
    this.updatePage('reg1');
}
  }
}
</script>
<style scoped>
.disable
{
  opacity:0.8;
  pointer-events:none;
}
.danger{
  font-size: 13px;
  color: #EE505A;
  margin: 4px 0px;
  text-align: left;
}
.wrapper{
  text-align: center;
}
.logo{
  margin-bottom: 5px;
  margin-top: 35px;
}
.inputs{
  display: flex;
  flex-direction: column;
}
.login_form{
  padding: 24px;
  background: #FFF;
  border-radius: 8px;
  margin: 60px auto;
  width: 368px;
  height: 540px;
}
.title{
  font-family: 'Manrope';
  font-style: normal;
  justify-content: center;
  font-weight: 800;
  font-size: 24px;
  line-height: 130%;
  color: #2B2D33;
}
input{
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 120%;
  color: #808185;
}
</style>
