<template>
  <div class="filters">
    <div class="filter">
      <!-- =========================
           1) НЕ "sources": СТАРАЯ ЛОГИКА
           ========================= -->
      <template v-if="page_type !== 'sources'">
        <!-- ============ Пример: вкладка "reports" ============ -->
        <div v-if="page_type === 'reports'" class="filter_item">
          <div class="wrap_titler">
            <div class="titler">Выберите скрипт обзвона</div>
            <div
              v-if="filters.current_scripts"
              class="clear"
              @click="filterRequest('select')"
            >
              <Icon name="close" />
            </div>
          </div>
          <v-combobox
            class="input_custom"
            v-model="filters.current_scripts"
            :items="selects"
            hide-selected
            solo
            no-data-text="Скриптов нет"
            color="#FEE600"
            height="40"
            hide-details
            error-count="0"
            width="450"
          />
        </div>

        <!-- Фильтр периода (старый) для reports/scripts/bases -->
        <div class="filter_item filter_range">
          <div class="wrap_titler">
            <div class="titler">Выберите период</div>
            <div
              v-if="filters.rangePicker.length"
              class="clear"
              @click="filterRequest('range')"
            >
              <Icon name="close"/>
            </div>
          </div>
          <v-menu
            v-model="menu3"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <div class="rel" v-bind="attrs" v-on="on">
                <v-text-field
                  v-model="dateRangeText"
                  readonly
                  class="input_custom input_range"
                  hide-details
                  height="40"
                />
                <Icon class="abs" name="calendar"/>
              </div>
            </template>

            <div class="data_picker__castum">
              <div class="data_picker__castum__menu">
                <v-chip-group active-class="primary--text" column>
                  <v-chip
                    v-for="(chip,i) in menuDataPicker"
                    :key="i"
                    column
                    label
                    @click="datePickerRange(chip.date)"
                  >
                    {{ chip.name }}
                  </v-chip>
                </v-chip-group>
              </div>
              <v-date-picker
                v-model="filters.rangePicker"
                @input="hideRange"
                range
                locale="ru"
                no-title
                first-day-of-week="1"
                color="#000"
                event-color="#00A5BC"
              />
            </div>
          </v-menu>
        </div>
      </template>

      <!-- =========================
           2) ВКЛАДКА "sources": НОВАЯ ЛОГИКА ДАТА+ВРЕМЯ
           ========================= -->
      <template v-else>
        <div class="filter_item">
          <div class="wrap_titler">
            <div class="titler">Выберите источник(и)</div>
            <div
              v-if="filters.current_source && filters.current_source.length"
              class="clear"
              @click="filterRequest('clear_source')"
            >
              <Icon name="close"/>
            </div>
          </div>
          <v-select
            v-model="filters.current_source"
            :items="selects.sources"
            multiple
            :menu-props="{ closeOnContentClick: false }"
            color="#FEE600"
            hide-details
            solo
            height="40"
            width="450"
            label=""
          >
            <template v-slot:item="{ item }">
              <v-list-item @click="toggleSource(item)" dense>
                <v-list-item-action>
                  <v-checkbox
                    :input-value="filters.current_source.includes(item)"
                    color="primary"
                    @click.stop="toggleSource(item)"
                  />
                </v-list-item-action>
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item, index }">
              <span v-if="index === 0">{{ item }}</span>
              <span v-else-if="index === 1">
                &nbsp;(+{{ filters.current_source.length - 1 }}...)
              </span>
            </template>
          </v-select>
        </div>

        <div class="filter_item">
          <div class="wrap_titler">
            <div class="titler">Выберите скрипт</div>
            <div
              v-if="filters.current_script_src"
              class="clear"
              @click="filterRequest('clear_script_src')"
            >
              <Icon name="close"/>
            </div>
          </div>
          <v-combobox
            class="input_custom"
            v-model="filters.current_script_src"
            :items="selects.scripts"
            hide-selected
            solo
            no-data-text="Скриптов нет"
            color="#FEE600"
            height="40"
            hide-details
            error-count="0"
            width="450"
          />
        </div>

        <!-- Блок выбора периода (date+time) через v-dialog -->
        <div class="filter_item filter_range">
          <div class="wrap_titler">
            <div class="titler">Выберите период</div>
            <div
              v-if="filters.startDateTime || filters.endDateTime"
              class="clear"
              @click="filterRequest('range')"
            >
              <Icon name="close" />
            </div>
          </div>
          <!-- Текстовое поле (открывает диалог) -->
          <div class="rel">
            <v-text-field
              v-model="displayDateRangeSources"
              readonly
              class="input_custom input_range"
              hide-details
              height="40"
              @click="dialogRange = true"
            />
            <Icon class="abs" name="calendar" />
          </div>
        </div>

        <!-- Диалог (дата+время) для Источников -->
        <v-dialog
          v-model="dialogRange"
          max-width="850px"
          scrollable
          :scrim-opacity="1"
        >
          <v-card>
            <v-card-title class="headline">Выберите период</v-card-title>
            <v-card-text>
              <div class="dialog-content">
                <div class="data_picker__castum__menu">
                  <v-chip-group active-class="primary--text" column>
                    <v-chip
                      v-for="(chip, i) in menuDataPickerSources"
                      :key="i"
                      column
                      label
                      @click="applyPresetSources(chip.date)"
                    >
                      {{ chip.name }}
                    </v-chip>
                  </v-chip-group>
                </div>

                <div class="picker-col">
                  <div class="date-time-title">Начало</div>
                  <v-date-picker
                    v-model="startDateLocal"
                    locale="ru"
                    no-title
                    scrollable
                    first-day-of-week="1"
                    color="#000"
                    event-color="#00A5BC"
                  />
                  <v-time-picker
                    v-model="startTimeLocal"
                    format="24hr"
                    scrollable
                    full-width
                    color="#000"
                  />
                </div>

                <div class="picker-col">
                  <div class="date-time-title">Конец</div>
                  <v-date-picker
                    v-model="endDateLocal"
                    locale="ru"
                    no-title
                    scrollable
                    first-day-of-week="1"
                    color="#000"
                    event-color="#00A5BC"
                  />
                  <v-time-picker
                    v-model="endTimeLocal"
                    format="24hr"
                    scrollable
                    full-width
                    color="#000"
                  />
                </div>
              </div>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="dialogRange = false">Отменить</v-btn>
              <v-btn color="#FEE600" dark @click="onSaveDateTimeSources">
                Сохранить
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>

      <!-- ================== КНОПКА "Применить" (общая) ================== -->
      <div
        class="apply"
        :class="{
          'apply-disabled': page_type==='sources' && (!filters.current_source || !filters.current_source.length)
        }"
        @click="filterRequest()"
      >
        Применить
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '../SetIcon.vue'

export default {
  name: "ReportsFilter",
  components: { Icon },
  props: [
    'page_type',   // "reports", "scripts", "bases", "sources"...
    'c_tab',
    'selects',     // { sources: [...], scripts: [...] }
    'callback'
  ],
  data() {
    return {
      // ====== СТАРАЯ ЛОГИКА (не sources) ======
      menu3: false,  // управление выпадающим меню (rangePicker)
      filters: {
        // старые поля
        rangePicker: [],   // [startDate, endDate] = ['YYYY-MM-DD','YYYY-MM-DD']

        current_scripts: null,
        current_source: [],
        current_script_src: null,

        // НОВЫЕ ПОЛЯ (только для sources)
        startDateTime: null,  // Date
        endDateTime: null     // Date
      },
      menuDataPicker: [
        { name: 'Сегодня', date: 'today' },
        { name: 'Вчера', date: 'yesterday' },
        { name: 'Эта неделя', date: 'thisWeek' },
        { name: 'Последние 7 дн.', date: 'Last7Days' },
        { name: 'Этот месяц', date: 'ThisMonth' },
        { name: 'Последние 30 дн.', date: 'Last30Days' },
        // { name: 'За все время', date: 'allTime' },
      ],
      countComputed: 0,

      // ====== НОВАЯ ЛОГИКА (для sources) ======
      dialogRange: false,
      // Локальные поля внутри диалога (источники)
      startDateLocal: null, // 'YYYY-MM-DD'
      startTimeLocal: '00:00',
      endDateLocal: null,   // 'YYYY-MM-DD'
      endTimeLocal: '23:59',
      menuDataPickerSources: [
        { name: 'Сегодня',        date: 'today' },
        { name: 'Вчера',          date: 'yesterday' },
        { name: 'Эта неделя',     date: 'thisWeek' },
        { name: 'Последние 7 дн.',  date: 'last7Days' },
        { name: 'Этот месяц',     date: 'thisMonth' },
        { name: 'Последние 30 дн.', date: 'last30Days' },
      ],
    };
  },
  computed: {
    /* ====== Вычисляемое поле (старая логика), показывающее "YYYY-MM-DD — YYYY-MM-DD" или "За все время" ====== */
    dateRangeText() {
      if (this.filters.rangePicker.length && this.countComputed >= -1) {
        return this.filters.rangePicker.join(' — ');
      } else {
        return 'За все время';
      }
    },

    /* ====== Вычисляемое поле (новая логика, для sources): "ДД.ММ.ГГГГ ЧЧ:ММ:СС - ДД.ММ.ГГГГ ЧЧ:ММ:СС" ====== */
    displayDateRangeSources() {
      const { startDateTime, endDateTime } = this.filters;
      if (!startDateTime || !endDateTime) {
        return 'За все время';
      }
      return (
        this.formatFullDateTime(startDateTime) + ' - ' + this.formatFullDateTime(endDateTime)
      );
    },
  },
  methods: {
    // =============== СТАРАЯ ЛОГИКА (не sources) ===============
		datePickerRange(val) {
  let today = new Date();

  // Для наглядности создаём две переменные start и end:
  let end = new Date(today);   // по умолчанию это «сегодня»
  let start = new Date(today); // копия

  switch (val) {
    case 'today':
      // сегодня (сегодня — сегодня)
      break;

    case 'yesterday':
      // вчера (один и тот же день — вчера)
      start.setDate(start.getDate() - 1);
      end.setDate(end.getDate() - 1);
      break;

			case 'thisWeek': {
    const dayOfWeek = today.getDay() || 7;
    start.setDate(start.getDate() - (dayOfWeek - 1));
    break;
  }

    case 'Last7Days':
      // последние 7 дней (включая сегодня)
      start.setDate(start.getDate() - 6);
      break;

    case 'ThisMonth':
      // с первого числа месяца по сегодня
      start = new Date(today.getFullYear(), today.getMonth(), 1);
      break;

    case 'Last30Days':
      // последние 30 дней
      start.setDate(start.getDate() - 29);
      break;

    // case 'allTime':
    //   this.filterRequest('range');
    //   return;
  }

  // Теперь формируем новые строки 'YYYY-MM-DD'
  const startStr = start.toISOString().split('T')[0];
  const endStr   = end.toISOString().split('T')[0];

  // === КЛЮЧЕВОЕ ИСПРАВЛЕНИЕ: вместо записи по индексам, ПОЛНОСТЬЮ ПЕРЕПРИСВАИВАЕМ массив ===
  this.filters.rangePicker = [startStr, endStr];

  // Если нужно закрыть меню, проверим длину
  this.hideRange();

  // Увеличим счётчик, если он участвует в логике
  this.countComputed++;
},

    hideRange() {
      if (this.filters.rangePicker.length > 1) this.menu3 = false;
    },

    // =============== НОВАЯ ЛОГИКА (Только для sources) ===============
    /** Применяем пресеты (сегодня, вчера и т.д.) для Источников (дата+время) */
    applyPresetSources(val) {
      let now = new Date();
      let start = new Date(), end = new Date();
      if (val !== 'yesterday') {
        end = new Date(now);
      }
      switch (val) {
        case 'today':
          start.setHours(0, 0, 0, 0);
          end.setHours(23, 59, 59, 999);
          break;
        case 'yesterday':
          now.setDate(now.getDate() - 1);
          start = new Date(now);
          end = new Date(now);
          start.setHours(0, 0, 0, 0);
          end.setHours(23, 59, 59, 999);
          break;
					case 'thisWeek': {
    const dayOfWeek = now.getDay() || 7;
    start.setDate(now.getDate() - (dayOfWeek - 1));
    start.setHours(0, 0, 0, 0);
    end.setHours(23, 59, 59, 999);
    break;
  }
        case 'last7Days':
          start = new Date(now);
          start.setDate(now.getDate() - 6);
          start.setHours(0, 0, 0, 0);
          end.setHours(23, 59, 59, 999);
          break;
        case 'thisMonth':
          start = new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0);
          end.setHours(23, 59, 59, 999);
          break;
        case 'last30Days':
          start = new Date(now);
          start.setDate(now.getDate() - 29);
          start.setHours(0, 0, 0, 0);
          end.setHours(23, 59, 59, 999);
          break;
      }
      this.startDateLocal = this.dateToLocalYmd(start);
      this.startTimeLocal = this.timeToHhMm(start);
      this.endDateLocal   = this.dateToLocalYmd(end);
      this.endTimeLocal   = this.timeToHhMm(end);
    },

    /** При нажатии "Сохранить" в диалоге (Источники) */
    onSaveDateTimeSources() {
      const start = this.makeDateTimeObject(this.startDateLocal, this.startTimeLocal);
      const end   = this.makeDateTimeObject(this.endDateLocal, this.endTimeLocal);
      if (start && end && start > end) {
        this.filters.startDateTime = end;
        this.filters.endDateTime   = start;
      } else {
        this.filters.startDateTime = start;
        this.filters.endDateTime   = end;
      }
      this.dialogRange = false;
    },

    // Формат "ДД.ММ.ГГГГ ЧЧ:ММ:СС"
    formatFullDateTime(d) {
      if (!d) return '';
      let dd = String(d.getDate()).padStart(2, '0');
      let mm = String(d.getMonth() + 1).padStart(2, '0');
      let yyyy = d.getFullYear();
      let hh = String(d.getHours()).padStart(2, '0');
      let m = String(d.getMinutes()).padStart(2, '0');
      let s = String(d.getSeconds()).padStart(2, '0');
      return `${dd}.${mm}.${yyyy} ${hh}:${m}:${s}`;
    },

    // =============== Общие вспомогательные методы ===============
    toggleSource(item) {
      const idx = this.filters.current_source.indexOf(item);
      if (idx >= 0) {
        this.filters.current_source.splice(idx, 1);
      } else {
        this.filters.current_source.push(item);
      }
    },

    /** При нажатии "Очистить" конкретное поле */
    filterRequest(clear = false) {
      if (clear) {
        switch (clear) {
          case 'select':
            this.filters.current_scripts = null;
            break;
          case 'range':
            // Старая логика:
            this.filters.rangePicker = [];
            // Новая логика (sources):
            this.filters.startDateTime = null;
            this.filters.endDateTime = null;
            break;
          case 'clear_source':
            this.filters.current_source = [];
            break;
          case 'clear_script_src':
            this.filters.current_script_src = null;
            break;
        }
        return;
      }

      // ======== Нажали "Применить" ========
      // Если вкладка !== 'sources': используем старый подход
      if (this.page_type !== 'sources') {
        // Проверяем порядок дат
        if (
          this.filters.rangePicker[0] &&
          this.filters.rangePicker[1] &&
          new Date(this.filters.rangePicker[0]) > new Date(this.filters.rangePicker[1])
        ) {
          const tmp = this.filters.rangePicker[0];
          this.filters.rangePicker[0] = this.filters.rangePicker[1];
          this.filters.rangePicker[1] = tmp;
        }

        if (this.page_type === 'reports') {
          this.callback({
            startDate: this.filters.rangePicker[0],
            endDate: this.filters.rangePicker[1],
            filterScript: this.filters.current_scripts
          });
        }
        else {
          // "scripts", "bases" и т.д.
          this.callback({
            startDate: this.filters.rangePicker[0],
            endDate: this.filters.rangePicker[1],
            filterScript: this.filters.current_scripts
          });
        }
      }
      else {
        // ===== Логика для "sources" =====
        if (!this.filters.current_source.length) {
          alert("Выберите хотя бы один источник");
          return;
        }
        const start = this.filters.startDateTime;
        const end   = this.filters.endDateTime;
        const startStr = start ? this.formatBack(start) : '';
        const endStr   = end   ? this.formatBack(end)   : '';
        this.callback({
          startDate: startStr,
          endDate: endStr,
          sources: this.filters.current_source,
          scripts: this.filters.current_script_src ? [this.filters.current_script_src] : []
        });
      }
    },

    // ============ Методы для преобразования дат (источники) ============
    dateToLocalYmd(d) {
      if (!d) return null;
      const yyyy = d.getFullYear();
      const mm = String(d.getMonth() + 1).padStart(2, '0');
      const dd = String(d.getDate()).padStart(2, '0');
      return `${yyyy}-${mm}-${dd}`;
    },
    timeToHhMm(d) {
      if (!(d instanceof Date)) return '00:00';
      const hh = String(d.getHours()).padStart(2, '0');
      const mm = String(d.getMinutes()).padStart(2, '0');
      return `${hh}:${mm}`;
    },
    makeDateTimeObject(dateStr, timeStr) {
      if (!dateStr) return null;
      const [yyyy, mm, dd] = dateStr.split('-').map(Number);
      let [hh, min] = [0, 0];
      if (timeStr) {
        [hh, min] = timeStr.split(':').map(Number);
      }
      const dateObj = new Date(yyyy, mm - 1, dd, hh, min, 0);
      return dateObj;
    },

    /** Формат отправки на бэкенд для sources: "YYYY-MM-DD HH:mm:ss" */
    formatBack(d) {
      const yyyy = d.getFullYear();
      const mm = String(d.getMonth() + 1).padStart(2, '0');
      const dd = String(d.getDate()).padStart(2, '0');
      const hh = String(d.getHours()).padStart(2, '0');
      const m = String(d.getMinutes()).padStart(2, '0');
      const s = String(d.getSeconds()).padStart(2, '0');
      return `${yyyy}-${mm}-${dd} ${hh}:${m}:${s}`;
    },
  },

  mounted() {
    // == Инициализация ==
    // Если НЕ sources => старый подход: по умолчанию rangePicker пуст (либо можно поставить сегодня - сегодня).
    if (this.page_type !== 'sources') {
      // Например, хотим по умолчанию "сегодня - сегодня" (можно оставить пусто, если нужно).
      // const today = new Date().toISOString().split('T')[0];
      this.filters.rangePicker = [];  // Или [today, today]
      // Если хотите "сегодня — сегодня":
      // this.filters.rangePicker = [today, today];
    }
    else {
      // Для "sources": выставляем сегодня 00:00:00 — 23:59:59
      const now = new Date();
      const start = new Date(now); start.setHours(0, 0, 0, 0);
      const end   = new Date(now); end.setHours(23, 59, 59, 999);

      this.startDateLocal = this.dateToLocalYmd(start);
      this.startTimeLocal = this.timeToHhMm(start);
      this.endDateLocal   = this.dateToLocalYmd(end);
      this.endTimeLocal   = this.timeToHhMm(end);

      this.filters.startDateTime = start;
      this.filters.endDateTime   = end;
    }
  }
};
</script>

<style scoped>
.filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #FFF4E8;
  padding: 22px 32px;
  border-radius: 8px 8px 0 0;
}

.filter {
  display: flex;
  position: relative;
}

.filter_item {
  position: relative;
  margin-right: 8px;
  display: flex;
  flex-direction: column;
}

.filter_range {
  min-width: 280px;
}

.wrap_titler {
  display: flex;
  justify-content: space-between;
}

.titler {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  color: #404247;
  text-align: left;
  margin-bottom: 6px;
}

.clear {
  cursor: pointer;
}

.rel {
  position: relative;
}

.input_range {
  min-width: 250px;
}

.abs {
  position: absolute;
  top: 13px;
  right: 13px;
  cursor: pointer;
}

.apply {
  background: #FFF;
  border: 1px solid #FED500;
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  color: #2B2D33;
  padding: 10px 20px;
  margin: 24px 30px 0 30px;
  max-height: 40px;
  cursor: pointer;
}
.apply-disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: default;
}

/* Старое выпадающее меню (rangePicker) */
.data_picker__castum {
  display: flex;
}
.data_picker__castum__menu {
  background: #FFFFFF;
  width: 200px;
  padding: 0 20px 0 20px;
}

/* Диалог для источников (дата+время) */
.dialog-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.date-time-title {
  font-weight: 600;
  margin-bottom: 8px;
  font-size: 16px;
}
.picker-col {
  display: flex;
  flex-direction: column;
  max-width: 280px;
  margin-right: 10px;
}
.data_picker__castum__menu {
  background: #FFFFFF;
  width: 180px;
  padding: 10px 15px;
  border-right: 1px solid #ccc;

}

/* Адаптив */
@media (max-width: 800px) {
  .filters {
    flex-direction: column;
    justify-content: center;
    border-radius: 0;
  }
  .filter {
    flex-direction: column;
  }
  .filter_item {
    margin-top: 10px;
    margin-right: 0;
    margin-left: 0;
  }
  .dialog-content {
    flex-direction: column;
  }
  .data_picker__castum__menu {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #ccc;
    margin-right: 0;
    margin-bottom: 10px;
  }
  .picker-col {
    width: 100%;
    margin: 0 0 10px 0;
  }
}
</style>
