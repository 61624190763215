<template>
  <div class="render">
    <v-breadcrumbs class="bc" v-if="!if_main" :items="breadcrumbs" large>
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item @click="returnMain('index')" :disabled="item.disabled">
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <div class="top">
      <h1>{{ current_integration.title }}</h1>
      <create-parent
        v-if="!if_main && current_integration.title != 'AmoCRM' && current_integration.title != 'Smsleads'"
        :show_dialog="edit_popup"
        :data="edit_data"
        :callback_refresh="renderData"
        :type="current_integration.title"
      />
    </div>
    <div class="text" v-if="!if_main">
      <p v-for="(item, i) in current_integration.paragraph" :key="i" v-html="item"></p>
    </div>
    <div v-if="if_main" class="content_table">
      <Card
        v-for="(item, i) in cards"
        :key="i"
        :item="item"
        :title="i"
        :func="item.click || changeInnerUrl"
      />
    </div>
    <div>
      <Table
        @edit="editDataEmit"
        @delete="deleteData"
        :page_type="'integration'"
        class="table_integrations"
        :callback_delete="deleteData"
        :callback_edit="editData"
        :items="action_items"
        :server="false"
        :perPage="10"
        :callback_refresh="renderData"
        :loading="loading"
        v-if="!if_main"
        :data="current_integration.data"
        :headers="current_integration.headers"
      />
    </div>
    <div v-if="current_integration.title === 'Smsleads'">
      <div v-if="smsleads_user_info">
        <!-- Отображение информации о пользователе -->
      </div>
      <div v-else>
        <!-- Форма для ввода токена -->
        <br />
        <h2 class="ta_left_v">Введите ваш токен Smsleads</h2>
        <br />
        <v-text-field v-model="smsleads_token" label="Токен" />
        <br />
        <v-btn depressed color="#FEE600" @click="saveSmsleadsToken">
          Сохранить токен
        </v-btn>
        <br />
        <div v-if="smsleads_response.visible" class="">
          {{ smsleads_response.message }}
        </div>
      </div>
    </div>

    <div v-if="current_integration.title == 'AmoCRM'">
      <br />
      <h2 class="ta_left_v">Добавить соединение</h2>
      <br />
      <v-text-field v-model="amo_data.name" label="Название интеграции" />
      <br />
      <v-text-field v-model="amo_data.amourl" label="URL вашей amoCRM" />
      <br />
      <v-text-field v-model="amo_data.client_id" label="ID интеграции" />
      <br />
      <v-text-field v-model="amo_data.client_secret" label="Секретный ключ" />
      <br />
      <v-text-field
        v-model="amo_data.redirect_uri"
        label="URL редиректа указаный при создании интеграции"
      />
      <br />
      <v-textarea v-model="amo_data.code" label="Код авторизации" />
      <br />
      <v-text-field v-model="amo_data.long_term_token" label="Долгосрочный токен" />
      <br />
      <v-btn depressed color="#FEE600" @click="registr_amo">
        Добавить соединение
      </v-btn>
      <br />
      <div v-if="amo_succes.visible" class="">
        {{ amo_succes.massage }} <br />
        {{ amo_succes.token }}
      </div>
    </div>
    <div class="bottom" v-if="if_main">
      Если у вас есть особый запрос пишите на почту:
      <a href="mailto:help@crmcalls.ru">help@crmcalls.ru</a>.
      Возможно, мы сделаем интеграцию индивидуально для вас.
    </div>
  </div>
</template>

<script>
import Card from '../components/Integrations/CardIntegration.vue'
import Table from '../components/Table/DataTable.vue'
import CreateParent from '../components/Modal/Integrations/CreateParent.vue'

export default {
  components: {
    Card,
    Table,
    CreateParent
  },
  data() {
    return {
      smsleads_token: '',
      smsleads_user_info: null,
      smsleads_response: {
        visible: false,
        message: ''
      },
      testtest: {},
      amo_list: {},
      amo_succes: {
        visible: false,
        massage: "",
        token: ""
      },
      amo_data: {
        name: "",
        amourl: "",
        client_id: "",
        client_secret: "",
        code: "",
        redirect_uri: "",
        long_term_token: ""
      },
      if_main: true,
      loading: true,
      edit_popup: false,
      integration_name: false,
      edit_data: {},
      current_integration: {},
      action_items: [
        { title: 'Редактировать', action: 'edit' },
        { title: 'Удалить', action: 'delete' }
      ],
      default: {
        title: 'Интеграции',
        paragraph: [],
        data: [],
        headers: []
      },
      cards: {
        'Webhook': {
          edit_popup: false,
          in_dev: false,
          title: 'Webhook',
          text: 'Запускайте звонки из других сервисов автоматически',
          icon: 'webhook',
          paragraph: [
            'Легкий способ запуска звонков в Конструкторе из вашего сервиса. Получите ссылку, указав сценарий и какие параметры вы будете передавать.',
            'Так, например, можно интегрировать роботов с вашей CRM: когда вы переместите карточку клиента в воронке, отправится webhook с нужными данными для совершения звонка.'
          ],
          data: [],
          headers: [
            { text: '№', align: 'start', value: 'id' },
            { text: 'Название', value: 'name' },
            { text: 'Тип запроса', value: 'method' },
            { text: 'URL', value: 'url' },
            { text: 'Действия', value: 'actions', sortable: false }
          ]
        },
        // 'Tilda' закомментирована
        'Битрикс24': {
          edit_popup: false,
          in_dev: true,
          title: 'Битрикс24',
          text: 'Интегрируйте робота с сайтом и автоматически запускайте звонки',
          icon: 'bitrix',
          paragraph: [
            'Легкий способ запустить звонок прямо из форм сайта Tilda. Получите ссылку в особом формате Tilda, указав сценарий и какие параметры мы будем от вас получать. Обращайтесь к Конструктору по ссылке, подставляя значения параметров для совершения звонков клиентам.',
            '<a>Как настроить webhook с формы вашего сайта на Tilda</a>'
          ],
          button: {
            text: 'Создать интеграцию с BITRIX'
          },
          data: [],
          headers: [
            { text: '№', align: 'start', value: 'id' },
            { text: 'Название', value: 'name' },
            { text: 'Тильда ID', value: 'tilda_id' },
            { text: 'Действия', value: 'actions', sortable: false }
          ]
        },
        'Telegram': {
          edit_popup: false,
          in_dev: false,
          title: 'Telegram',
          text: 'Пересылайте информацию о звонках в личные сообщения или чаты telegram',
          icon: 'telegram',
          paragraph: [
            'Подключите бота и получайте информацию от робота во время звонка в личные или групповые чаты. Что и когда отправлять настраивается внутри сценария с помощью блока «Отправить сообщение в Telegram»'
          ],
          button: {
            text: 'Создать интеграцию telegram'
          },
          data: [],
          headers: [
            { text: '№', align: 'start', value: 'id' },
            { text: 'Название', value: 'name' },
            { text: 'Telegram id', value: 'tg_id' },
            { text: 'Действия', value: 'actions', sortable: false }
          ]
        },
        'AmoCRM': {
          edit_popup: false,
          in_dev: false,
          title: 'AmoCRM',
          text: 'Вебхук для AmoCRM и перемещение лида по воронке',
          icon: 'amocrm',
          paragraph: [
            'Интеграция c AmoCRM. Заполните форму и подключитесь. ',
            'Веб-хук для AmoCRM такого вида: https://gate.crmcalls.ru/api/web/amoWebHook/{id базы}/{номер юзера}/{id интеграции} '
          ],
          button: {
            text: 'Создать интеграцию telegram'
          },
          data: [],
          headers: [
            { text: 'ID', align: 'start', value: 'id' },
            { text: 'Название', value: 'name' }
          ]
        },
        'RESTAPI': {
          edit_popup: false,
          in_dev: false,
          title: 'RESTAPI',
          text: 'Интеграция по REST API для получения данных',
          icon: 'restapi',
          paragraph: ['Интеграция REST API '],
          button: {
            text: 'Создать интеграцию REST API'
          },
          data: [],
          headers: [
            { text: 'ID', align: 'start', value: 'id' },
            { text: 'Название', value: 'name' }
          ],
          click: () => {
            window.open('/RESTapi.pdf');
          }
        },
        'Smsleads': {
          edit_popup: false,
          in_dev: false,
          title: 'Smsleads',
          text: 'Интеграция с Smsleads для отправки sms во время скрипта звонка',
          icon: 'smsleads',
          paragraph: [
            'Введите ваш токен для подключения к Smsleads и получения данных пользователя.'
          ],
          data: [],
          headers: [
            { text: 'ID', align: 'start', value: '_id' },
            { text: 'Имя', value: 'name' },
            { text: 'Телефон', value: 'phone' },
            { text: 'Email', value: 'email' },
            { text: 'Баланс', value: 'balance' },
            { text: 'Действия', value: 'actions', sortable: false }
          ]
        }
      },
      breadcrumbs: [
        {
          text: 'Интеграции',
          disabled: false,
          href: 'integrations'
        },
        {
          text: 'Link 2',
          disabled: true,
          href: 'integrations'
        }
      ]
    };
  },
  methods: {
    checkSmsleadsToken() {
      return this.$http.get('integrations/smsleads_get_user_info')
        .then((response) => {
          console.log('Ответ от сервера:', response);
          if (response.status === 200) {
            if (response.data.status === 200) {
              this.smsleads_user_info = response.data.data;
              console.log('Данные пользователя:', this.smsleads_user_info);
              this.current_integration.data = [this.smsleads_user_info];
            } else {
              this.smsleads_user_info = null;
              console.log('Ошибка получения данных пользователя');
              this.smsleads_response.visible = true;
              this.smsleads_response.message = response.data.message || 'Ошибка при получении информации о пользователе.';
            }
          } else {
            this.smsleads_user_info = null;
            console.log('Ошибка HTTP при получении данных пользователя');
            this.smsleads_response.visible = true;
            this.smsleads_response.message = 'Ошибка при получении информации о пользователе.';
          }
        })
        .catch((error) => {
          console.error('Ошибка при запросе:', error);
          this.smsleads_user_info = null;
          this.smsleads_response.visible = true;
          this.smsleads_response.message = 'Ошибка при получении информации о пользователе.';
        });
    },
    saveSmsleadsToken() {
      if (!this.smsleads_token.trim()) {
        this.smsleads_response.visible = true;
        this.smsleads_response.message = 'Пожалуйста, введите токен.';
        return;
      }
      console.log(this.smsleads_token.trim());
      this.$http.post('integrations/smsleads_save_token', {
        tokensmsleads: this.smsleads_token.trim()
      }, (response) => {
        console.log('Ответ от сервера при сохранении токена:', response);
        if (response && response.status === 200) {
          this.checkSmsleadsToken();
        } else {
          this.smsleads_response.visible = true;
          const message = response && response.data && response.data.message
            ? response.data.message
            : 'Ошибка при сохранении токена.';
          this.smsleads_response.message = message;
        }
      });
    },
    getAmoIntegrationsList() {
      this.$http.post("/integrations/get_amo_integration_list", {}, (res) => {
        if (res != false) {
          this.current_integration.data = res;
          this.amo_list = res;
        } else {
          this.amo_list = {};
        }
      });
    },
    registr_amo() {
      console.log(this.amo_data);
      this.$http.post("/integrations/registr_amo", this.amo_data, (res) => {
        if (res != false) {
          this.amo_succes.visible = true;
          this.amo_succes.massage = "Соединение прошло успешно. Ваш токен:";
          this.amo_succes.token = res;
          this.getAmoIntegrationsList();
        } else {
          this.amo_succes.visible = true;
          this.amo_succes.massage = "Что-то пошло не так";
        }
      });
    },
    editDataEmit(item) {
      this.editData(true, { id: item.guid, item: item });
    },
    editData(visible, data) {
      this.edit_data = data;
      this.edit_popup = visible;
    },
    deleteData(item) {
      this.$http.post("/integrations/delete", {
        type: this.integration_name,
        id: item.guid
      }, () => {
        this.renderData();
      });
    },
    renderData(norender = false) {
      this.edit_popup = false;
      if (!norender) {
        this.loading = true;
        // Обнуляем данные, чтобы не показывалось "Нет данных"
        this.current_integration.data = [];
        if (!this.if_main) {
          if (this.integration_name === "AmoCRM") {
            this.getAmoIntegrationsList();
            this.loading = false;
          } else if (this.integration_name === 'Smsleads') {
            this.checkSmsleadsToken()
              .finally(() => {
                this.loading = false;
              });
          } else {
            this.$http.post("/integrations/list", { type: this.integration_name }, (data) => {
              this.current_integration.data = data;
              this.loading = false;
            });
          }
        }
      }
    },
    returnMain(page) {
      if (page === "index") {
        this.if_main = true;
      }
    },
    changeInnerUrl(item) {
      if (!item.in_dev) {
        this.integration_name = item.title;
        this.current_integration = this.cards[item.title];
        this.breadcrumbs[1].text = item.title;
        this.if_main = false;
        this.renderData();
      }
    },
    reset() {
      this.current_integration = this.default;
    }
  },
  mounted() {
    // Для AmoCRM можно оставить начальную загрузку,
    // а для Smsleads теперь данные подгружаются только при переходе в раздел
    this.getAmoIntegrationsList();
  },
  beforeMount() {
    this.current_integration = this.default;
  }
};
</script>

<style scoped>
.ta_left_v {
  text-align: left;
}
.top {
  margin-top: 24px;
  margin-bottom: 8px;
}
.list {
  padding-left: 0;
}
.content_table {
  /* display: flex; */
  /* flex-wrap: wrap; */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
  align-items: center;
  gap: 8px;
  margin-bottom: 20px;
}
.bc {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
}
.bc li a {
  color: #404247 !important;
}
.text {
  text-align: left;
}
.table_integrations {
}
@media (max-width: 812px) {
  .top {
    justify-content: center;
    flex-direction: column;
  }
  h1 {
    margin-bottom: 15px;
  }
  .v-application .my-12 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
}
@media (max-width: 400px) {
  .text {
    padding: 10px;
  }
  .content_table {
    padding: 10px;
  }
}
</style>
