<template>
	<div class="text-center">
		<v-dialog v-model="dialog" width="530">
			<div @click="dialogclose" class="close">
				<Icon name="close"/>
			</div>
			<v-card class="wrapper">
				<div class="modal_title">
					Идентификация пользователя
				</div>
				<div class="desc">
					<span v-if="value === radios[0]">
						Для прохождения процедуры идентификации необходимо выбрать пользователя, загрузить необходимые
						документы и дождаться проверки
					</span>
					<span v-if="value === radios[1]">
						Для прохождения процедуры идентификации необходимо выбрать пользователя, загрузить необходимые 
						документы и дождаться проверки и формирования договора-лицензии.
					</span>
				</div>

				<div class="items_wrapper">
					<!-- Выбор физ. или юр. лица -->
					<div class="item_row">
						<div class="type_name">Пользователь</div>
						<v-radio-group row v-model="value">
							<v-radio
								active-class="thunder"
								color="#FEE600"
								light
								mandatory
								v-for="n in radios"
								:key="n"
								:label="n"
								:value="n"
							></v-radio>
						</v-radio-group>
					</div>

					<!-- Выбор формы организации (ООО/ИП) - показываем, если выбран "Юридическое лицо" -->
					<div v-if="value === radios[1]" class="item_row">
  <div class="titler" style="width: 100%; text-align: center;">
    Форма организации
  </div>
  <v-radio-group row v-model="valueType">
    <v-radio
      active-class="thunder"
      color="#FEE600"
      light
      mandatory
      v-for="n in radiosType"
      :key="n"
      :label="n"
      :value="n"
    ></v-radio>
  </v-radio-group>
</div>

					<!-- Физ. лицо -->
					<div v-if="value == radios[0]" class="item_tab">
						<div class="item_row">
							<div class="aftertext">
								Допустимо использовать фотографию или скан. Все данные должны быть отчётливо видны.
							</div>
						</div>
						<div class="item_row">
							<div class="titler">Скан паспорта</div>
							<div class="label">Основной разворот</div>
							<FileLoad 
								types=".pdf, .jpg, .png" 
								url="/cabinet/load" 
								formats=".pdf, .doc, .jpg, .png" 
								:isload="Boolean(pasportOneFile)"
								:callback_file="pasportOneLoadFile" 
								:linkFile="pasportOneFile" 
								:valid="validRadiosForm.individual"
							/>
							<div class="label">Страница с актуальным адресом регистрации по месту жительства</div>
							<FileLoad 
								types=".pdf, .jpg, .png" 
								url="/cabinet/load" 
								formats=".pdf, .doc, .jpg, .png" 
								:isload="Boolean(pasportTwoFile)"
								:callback_file="pasportTwoLoadFile" 
								:linkFile="pasportTwoFile" 
								:valid="validRadiosForm.individual"
							/>
						</div>
						<div class="item_row">
							<div class="titler">Ваше фото с паспортом в руках</div>
							<PhotoLoad 
								types="jpg, png" 
								url="/cabinet/loadPhoto" 
								formats=".jpg, .png" 
								:isload="Boolean(passportAndPersonPhoto)"
								:callback_file="passportAndPersonPhotoLoad" 
								:photoLink="passportAndPersonPhoto" 
								:valid="validRadiosForm.individual"
							/>
							<div class="aftertext">
								Сделайте селфи с паспортом в руках. Держите раскрытый основной
								разворот паспорта рядом с лицом, должно быть четко видно: вас, фото в паспорте, ФИО и другие важные данные
							</div>
						</div>
					</div>

					<!-- Юр. лицо -->
					<div v-if="value == radios[1]" class="item_tab">

						<!-- ООO -->
						<div v-if="valueType === radiosType[0]" class="item_row">
							<div class="label">Полное официальное наименование</div>
							<v-text-field 
								v-model="companyNameOoo" 
								placeholder="ООО ”Тестовая компания”"
							></v-text-field>
							<div v-if="validRadiosForm.entityOOO && !companyNameOoo" class="valid_value">
								'Обязательно к заполнению!'
							</div>
						</div>

						<div v-if="valueType === radiosType[0]" class="item_row">
							<div class="label">ИНН</div>
							<v-text-field 
								v-model="innOoo" 
								placeholder="0000000000" 
								type="number"
							></v-text-field>
							<div v-if="validRadiosForm.entityOOO && !innOoo" class="valid_value">
								Обязательно к заполнению!
							</div>
						</div>

						<div v-if="valueType === radiosType[0]" class="item_row">
							<div class="label">КПП</div>
							<v-text-field 
								v-model="kppOoo" 
								placeholder="000000000" 
								type="number"
							></v-text-field>
							<div v-if="validRadiosForm.entityOOO && !kppOoo" class="valid_value">
								Обязательно к заполнению!
							</div>
						</div>

						<div v-if="valueType === radiosType[0]" class="item_row">
							<div class="label">ОГРН</div>
							<v-text-field 
								v-model="ogrnOoo" 
								placeholder="000000000000000" 
								type="number"
							></v-text-field>
							<div v-if="validRadiosForm.entityOOO && !ogrnOoo" class="valid_value">
								Обязательно к заполнению!
							</div>
						</div>

						<div v-if="valueType === radiosType[0]" class="item_row">
							<div class="label">Расчётный счет</div>
							<v-text-field 
								v-model="rsOoo" 
								placeholder="00000000000000000000" 
								type="number"
							></v-text-field>
							<div v-if="validRadiosForm.entityOOO && !rsOoo" class="valid_value">
								Обязательно к заполнению!
							</div>
						</div>

						<div v-if="valueType === radiosType[0]" class="item_row">
							<div class="label">Корреспондентский счет</div>
							<v-text-field 
								v-model="korOoo" 
								placeholder="00000000000000000000" 
								type="number"
							></v-text-field>
							<!-- корсчет не обязателен, проверим длину только если не пустое -->
						</div>

						<div v-if="valueType === radiosType[0]" class="item_row">
							<div class="label">БИК</div>
							<v-text-field 
								v-model="bikOoo" 
								placeholder="000000000" 
								type="number"
							></v-text-field>
							<div v-if="validRadiosForm.entityOOO && !bikOoo" class="valid_value">
								Обязательно к заполнению!
							</div>
						</div>

						<div v-if="valueType === radiosType[0]" class="item_row">
							<div class="label">Банк</div>
							<v-text-field 
								v-model="bankOoo" 
								placeholder="АО 'АЛЬФА-БАНК' г. Москва"
							></v-text-field>
							<div v-if="validRadiosForm.entityOOO && !bankOoo" class="valid_value">
								Обязательно к заполнению!
							</div>
						</div>

						<div v-if="valueType === radiosType[0]" class="item_row">
							<div class="label">Юридический адрес</div>
							<v-text-field 
								v-model="addressOoo" 
								placeholder="108823, Московская область, город Подольск, Подольская улица..."
							></v-text-field>
							<div v-if="validRadiosForm.entityOOO && !addressOoo" class="valid_value">
								Обязательно к заполнению!
							</div>
						</div>

						<div v-if="valueType === radiosType[0]" class="item_row">
							<div class="aftertext">
								Допустимо использовать фотографию или скан. Все данные должны быть отчётливо видны.
							</div>
						</div>

						<div v-if="valueType === radiosType[0]" class="item_row">
							<!-- Свидетельство о государственной регистрации -->
							<div class="titler">Свидетельство о государственной регистрации</div>
							<FileLoad 
								types=".pdf, .jpg, .png" 
								url="/cabinet/load" 
								formats=".pdf, .doc, .jpg, .png" 
								:isload="Boolean(InnFile)"
								:callback_file="InnLoadFile" 
								:linkFile="InnFile" 
								:valid="validRadiosForm.entityOOO"
							/>
						</div>

						<div v-if="valueType === radiosType[0]" class="item_row">
							<!-- Приказ или решение о назначении -->
							<div class="titler">
								Приказ или решение о назначении руководителя организации
							</div>
							<FileLoad 
								types=".pdf, .jpg, .png" 
								url="/cabinet/load" 
								formats=".pdf, .doc, .jpg, .png" 
								:isload="Boolean(SolutionCompanyFile)"
								:callback_file="SolutionCompanyLoadFile" 
								:linkFile="SolutionCompanyFile" 
								:valid="validRadiosForm.entityOOO"
							/>

							<!-- Убрали Приказ о назначении руководителя... -->

							<div class="titler">Карточка организации</div>
							<FileLoad 
								types="doc, .pdf, .jpg, .png" 
								url="/cabinet/load" 
								formats="doc, .pdf, .jpg, .png" 
								:isload="Boolean(CompanyFile)"
								:callback_file="CompanyLoadFile" 
								:linkFile="CompanyFile" 
								:valid="validRadiosForm.entityOOO"
							/>
						</div>

						<!-- ИП -->
						<div v-if="valueType === radiosType[1]" class="item_row">
							<div class="label">Полное официальное наименование</div>
							<v-text-field 
								v-model="companyNameIp" 
								placeholder="ИП Иванов Иван Иванович"
							></v-text-field>
							<div v-if="validRadiosForm.entityIP && !companyNameIp" class="valid_value">
								'Обязательно к заполнению!'
							</div>
						</div>

						<div v-if="valueType === radiosType[1]" class="item_row">
							<div class="label">ИНН</div>
							<v-text-field 
								v-model="innIp" 
								placeholder="000000000000" 
								type="number"
							></v-text-field>
							<div v-if="validRadiosForm.entityIP && !innIp" class="valid_value">
								Обязательно к заполнению!
							</div>
						</div>

						<div v-if="valueType === radiosType[1]" class="item_row">
							<div class="label">ОГРНИП</div>
							<v-text-field 
								v-model="ogrnip" 
								placeholder="000000000000000" 
								type="number"
							></v-text-field>
							<div v-if="validRadiosForm.entityIP && !ogrnip" class="valid_value">
								Обязательно к заполнению!
							</div>
						</div>

						<div v-if="valueType === radiosType[1]" class="item_row">
							<div class="label">Расчётный счет</div>
							<v-text-field 
								v-model="rsIp" 
								placeholder="00000000000000000000" 
								type="number"
							></v-text-field>
							<div v-if="validRadiosForm.entityIP && !rsIp" class="valid_value">
								Обязательно к заполнению!
							</div>
						</div>

						<div v-if="valueType === radiosType[1]" class="item_row">
							<div class="label">Корреспондентский счет</div>
							<v-text-field 
								v-model="korIp" 
								placeholder="00000000000000000000" 
								type="number"
							></v-text-field>
							<!-- Не обязательно, проверяем только если не пустое -->
						</div>

						<div v-if="valueType === radiosType[1]" class="item_row">
							<div class="label">БИК</div>
							<v-text-field 
								v-model="bikIp" 
								placeholder="000000000" 
								type="number"
							></v-text-field>
							<div v-if="validRadiosForm.entityIP && !bikIp" class="valid_value">
								Обязательно к заполнению!
							</div>
						</div>

						<div v-if="valueType === radiosType[1]" class="item_row">
							<div class="label">Банк</div>
							<v-text-field 
								v-model="bankIp" 
								placeholder="АО 'АЛЬФА-БАНК' г. Москва"
							></v-text-field>
							<div v-if="validRadiosForm.entityIP && !bankIp" class="valid_value">
								Обязательно к заполнению!
							</div>
						</div>

						<div v-if="valueType === radiosType[1]" class="item_row">
							<div class="label">Юридический адрес</div>
							<v-text-field 
								v-model="addressIp" 
								placeholder="108823, Московская область, город Подольск, Подольская улица..."
							></v-text-field>
							<div v-if="validRadiosForm.entityIP && !addressIp" class="valid_value">
								Обязательно к заполнению!
							</div>
						</div>

						<div v-if="valueType === radiosType[1]" class="item_row">
							<div class="aftertext">
								Допустимо использовать фотографию или скан. Все данные должны быть отчётливо видны.
							</div>
						</div>

						<div v-if="valueType === radiosType[1]" class="item_row">
							<div class="titler">
								Свидетельство о государственной регистрации в качестве индивидуального предпринимателя
							</div>
							<FileLoad 
								types=".pdf, .jpg, .png" 
								url="/cabinet/load" 
								formats=".pdf, .doc, .jpg, .png" 
								:isload="Boolean(InnFile)"
								:callback_file="InnLoadFile" 
								:linkFile="InnFile" 
								:valid="validRadiosForm.entityIP" 
							/>
						</div>

						<div v-if="valueType === radiosType[1]" class="item_row">
							<div class="titler">Скан паспорта</div>
							<div class="label">Основной разворот</div>
							<FileLoad 
								types=".pdf, .jpg, .png" 
								url="/cabinet/load" 
								formats=".pdf, .doc, .jpg, .png" 
								:isload="Boolean(pasportOneFile)"
								:callback_file="pasportOneLoadFile" 
								:linkFile="pasportOneFile" 
								:valid="validRadiosForm.entityIP"
							/>
							<div class="label">
								Страница с актуальным адресом регистрации по месту жительства
							</div>
							<FileLoad 
								types=".pdf, .jpg, .png" 
								url="/cabinet/load" 
								formats=".pdf, .doc, .jpg, .png" 
								:isload="Boolean(pasportTwoFile)"
								:callback_file="pasportTwoLoadFile" 
								:linkFile="pasportTwoFile" 
								:valid="validRadiosForm.entityIP"
							/>
						</div>
					</div>

					<!-- Email (и для ФЛ, и для ЮЛ) -->
					<div class="item_row">
						<div class="label mb-2">
							{{ value === radios[0] ? 'Email' : 'Авторизованный Email организации'}}
						</div>
						<v-text-field 
							v-model="Email" 
							placeholder="Укажите email"
						></v-text-field>
						<div 
							v-if="(validRadiosForm.individual && !Email) 
								|| (validRadiosForm.entityOOO && !Email) 
								|| (validRadiosForm.entityIP && !Email)"
							class="valid_value"
						>
							'Обязательно к заполнению!'
						</div>
					</div>

					<div v-if="valueType === radiosType[1]" class="desc mb-4">
						За все действия совершенные в личном кабинете ответственность несет Лицензиат согласно договору-лицензии
					</div>

					<!-- Выводим сообщение об ошибке длины (если есть) -->
					<p class="clr_red" v-if="error_length_message">
						{{ error_length_message }}
					</p>

					<p class="clr_red" v-if="error_validate_doc">
						Примите согласие на рекламнно информационные материалы
					</p>
					<p class="clr_red" v-if="error_validate_doc_two">
						Примите согласие на несение ответственности за действия в личном кабинете
					</p>
					<p class="clr_red" v-if="error_validate">
						Форма заполнена не верно
					</p>

					<div @click="sendForm" class="my_btn btn_save">Отправить документы</div>
					<div @click="dialogclose" class="cancel_btn">Отменить</div>
				</div>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import Icon from '../../SetIcon.vue'
//import FileDrop from '../../FileDrop.vue'
import PhotoLoad from "@/components/PhotoLoad";
import FileLoad from "@/components/FileLoad";

export default {
	components: {
		Icon,
		// FileDrop,
		PhotoLoad, 
		FileLoad
	},
	props: ['title', 'btn', 'dialogstate', 'dialogclose', 'setbar'],
	data() {
		return {
			check_doc: true,
			check_doc_two: true,
			dialog: this.dialogstate,
			radios: [
				'Физическое лицо',
				'Юридическое лицо'
			],
			radiosType: [
				'ООО',
				'ИП'
			],
			// radiosWorker: [ ... ],

			value: 'Физическое лицо',
			valueType: 'ООО',

			// СТАРЫЕ ПОЛЯ (оставляем закомментированными, чтобы не потерять)
			CompanyName: "",
			CompanyInn: "",
			// ...

			pasportOneFile: "",
			pasportTwoFile: "",
			passportAndPersonPhoto: '',

			CompanyFile: "",
			InnFile: '',
			SolutionCompanyFile: '',
			OrderCompanyFile: '',

			Email: '',

			// Новые поля ООО
			companyNameOoo: "",
			innOoo: "",
			kppOoo: "",
			ogrnOoo: "",
			rsOoo: "",
			korOoo: "",
			bikOoo: "",
			bankOoo: "",
			addressOoo: "",

			// Новые поля ИП
			companyNameIp: "",
			innIp: "",
			ogrnip: "",
			rsIp: "",
			korIp: "",
			bikIp: "",
			bankIp: "",
			addressIp: "",

			validRadiosForm: {
				individual: false,
				entityOOO: false,
				entityIP: false
			},
			error_validate: false,
			error_validate_doc: false,
			error_validate_doc_two: false,

			// Новая переменная для вывода ошибки длины
			error_length_message: ''
		}
	},
	watch: {
		dialogstate(nowValue) {
			this.dialog = nowValue
		}
	},
	methods: {
		sendForm() {
			// Проверка галочек
			if (!this.check_doc) {
				this.error_validate_doc = true
				return
			} else {
				this.error_validate_doc = false
			}
			if (!this.check_doc_two) {
				this.error_validate_doc_two = true
				return
			} else {
				this.error_validate_doc_two = false
			}

			let form = false

			// Физическое лицо
			if (this.value === this.radios[0]) {
				if (
					this.pasportOneFile &&
					this.pasportTwoFile &&
					this.passportAndPersonPhoto &&
					this.Email
				) {
					form = {
						type_user: this.radios[0],
						pasport_one: this.pasportOneFile,
						pasport_two: this.pasportTwoFile,
						photo_passport_person: this.passportAndPersonPhoto,
						email: this.Email
					}
				} else {
					form = false
					this.errorValid()
					this.validRadiosForm.individual = true
				}
			}

			// Юридическое лицо
			if (this.value === this.radios[1]) {
				// ООО
				if (this.valueType === this.radiosType[0]) {
					if (
						this.companyNameOoo &&
						this.innOoo &&
						this.kppOoo &&
						this.ogrnOoo &&
						this.rsOoo &&
						this.bikOoo &&
						this.bankOoo &&
						this.addressOoo &&
						this.InnFile &&
						this.SolutionCompanyFile &&
						this.CompanyFile &&
						this.Email
					) {
						// Перед проверкой длины обнуляем сообщение об ошибке
						this.error_length_message = ''

						// Проверки длины:
						if (this.innOoo.length !== 10) {
							return this.errorLength("ИНН (ООО) должен содержать ровно 10 цифр")
						}
						if (this.kppOoo.length !== 9) {
							return this.errorLength("КПП (ООО) должен содержать ровно 9 цифр")
						}
						if (this.ogrnOoo.length !== 13) {
							return this.errorLength("ОГРН (ООО) должен содержать 13 цифр")
						}
						if (this.rsOoo.length !== 20) {
							return this.errorLength("Расчётный счёт (ООО) должен содержать 20 цифр")
						}
						if (this.korOoo && this.korOoo.length !== 20) {
							return this.errorLength("Корр. счёт (ООО) должен содержать 20 цифр (или оставить пустым)")
						}
						if (this.bikOoo.length !== 9) {
							return this.errorLength("БИК (ООО) должен содержать 9 цифр")
						}

						form = {
							type_user: this.radios[1],
							type_organization: this.radiosType[0],

							company_name: this.companyNameOoo,
							inn: this.innOoo,
							kpp: this.kppOoo,
							ogrn: this.ogrnOoo,
							r_s: this.rsOoo,
							kor_s: this.korOoo,
							bik: this.bikOoo,
							bank: this.bankOoo,
							address: this.addressOoo,

							company_inn_file: this.InnFile,
							company_solution: this.SolutionCompanyFile,
							company_file: this.CompanyFile,

							email: this.Email
						}
					} else {
						form = false
						this.errorValid()
						this.validRadiosForm.entityOOO = true
					}
				}

				// ИП
				if (this.valueType === this.radiosType[1]) {
					if (
						this.companyNameIp &&
						this.innIp &&
						this.ogrnip &&
						this.rsIp &&
						this.bikIp &&
						this.bankIp &&
						this.addressIp &&
						this.InnFile &&
						this.pasportOneFile &&
						this.pasportTwoFile &&
						this.Email
					) {
						// Сброс ошибки
						this.error_length_message = ''

						// Проверки длины:
						if (this.innIp.length !== 12) {
							return this.errorLength("ИНН (ИП) должен содержать 12 цифр")
						}
						if (this.ogrnip.length !== 13) {
							return this.errorLength("ОГРНИП (ИП) должен содержать 13 цифр")
						}
						if (this.rsIp.length !== 20) {
							return this.errorLength("Расчётный счёт (ИП) должен содержать 20 цифр")
						}
						if (this.korIp && this.korIp.length !== 20) {
							return this.errorLength("Корр. счёт (ИП) должен содержать 20 цифр (или оставить пустым)")
						}
						if (this.bikIp.length !== 9) {
							return this.errorLength("БИК (ИП) должен содержать 9 цифр")
						}

						form = {
							type_user: this.radios[1],
							type_organization: this.radiosType[1],

							company_name: this.companyNameIp,
							inn: this.innIp,
							ogrnip: this.ogrnip,
							r_s: this.rsIp,
							kor_s: this.korIp,
							bik: this.bikIp,
							bank: this.bankIp,
							address: this.addressIp,

							company_inn_file: this.InnFile,
							pasport_one: this.pasportOneFile,
							pasport_two: this.pasportTwoFile,

							email: this.Email
						}
					} else {
						form = false
						this.errorValid()
						this.validRadiosForm.entityIP = true
					}
				}
			}

			// Отправка формы
			if (form !== false) {
				this.$http.post("/cabinet/update_userinfo", { form }, (result) => {
					if (result) {
						this.dialog = false
						this.$store.commit('user/setStatus', "waiting")
						this.setbar("waiting")
					}
				})
			}
		},

		errorValid() {
			this.error_validate = true
			setTimeout(() => {
				this.error_validate = false
			}, 4000)
		},

		errorLength(msg) {
			// Пишем сообщение об ошибке длины
			this.error_length_message = msg
			// Можно дополнительно останавливать форму, что мы и делаем 'return'
			this.error_validate = true
		},

		pasportOneLoadFile(file) {
			this.pasportOneFile = file || ''
		},
		pasportTwoLoadFile(file) {
			this.pasportTwoFile = file || ''
		},
		passportAndPersonPhotoLoad(file) {
			this.passportAndPersonPhoto = file || ''
		},
		CompanyLoadFile(file) {
			this.CompanyFile = file || ''
		},
		InnLoadFile(file) {
			this.InnFile = file || ''
		},
		SolutionCompanyLoadFile(file) {
			this.SolutionCompanyFile = file || ''
		},
		OrderCompanyLoadFile(file) {
			this.OrderCompanyFile = file || ''
		},
	}
}
</script>

<style scoped>
.items_wrapper {
	display: flex;
	flex-direction: column;
	margin-top: 24px;
}
.thunder {
	color: red;
}
.valid_value {
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 130%;
	text-align: center;
	width: 100%;
	color: #ff3737;
}
.btn_save {
	background: #FEE600;
	cursor: pointer;
	border-radius: 8px;
	font-style: normal;
	font-weight: 600;
	font-size: 15px;
	line-height: 120%;
	color: #2B2D33;
	margin-bottom: 0;
}
.label {
	font-style: normal;
	font-weight: 600;
	font-size: 15px;
	line-height: 120%;
	color: #404247;
	margin-bottom: 6px;
	text-align: left;
}
.clr_red {
	color: red;
}
.type_name {
	font-style: normal;
	font-weight: 700;
	font-size: 17px;
	line-height: 120%;
	color: #404247;
	margin-bottom: 13px;
}
.wrapper {
	padding: 32px;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.desc {
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	line-height: 130%;
	color: #404247;
}
.item_row {
	display: flex;
	margin-bottom: 12px;
	flex-direction: column;
}
.titler {
	text-align: left;
	margin-bottom: 8px;
	font-style: normal;
	font-weight: 600;
	font-size: 17px;
	line-height: 120%;
	color: #404247;
}
.modal_title {
	padding: 0 !important;
	font-style: normal;
	font-weight: 800;
	font-size: 24px;
	margin-top: 19px;
	margin-bottom: 24px;
	text-align: center;
	line-height: 130%;
	color: #2B2D33;
}
.cancel_btn {
	cursor: pointer;
	margin-top: 10px;
}
.check_policy_text {
	text-align: left;
	margin-bottom: 15px;
}
</style>
