<template>
  <div v-if="!this.$store.getters['user/getUserFields'].is_admin">
    Вы не администратор, авторизируйтесь как администратор!
  </div>
  <div v-else style="width: 100%; padding: 10px">
    <h3>Количество активных обзвонов: {{ list.length }}</h3>

    <button
      :disabled="stopAllDisabled"
      @click="stopAll"
      style="background: #f31510; padding: 8px; margin: 5px; border-radius: 5px; color: white"
    >
      Остановить все обзвоны
    </button>

    <button
      v-if="hasStoppedCalls"
      :disabled="resumeAllDisabled"
      @click="resumeAll"
      style="background: orange; padding: 8px; margin: 5px; border-radius: 5px; color: white"
    >
      Возобновить остановленные
    </button>

    <div style="width: 100%; margin-top: 20px">
      <h3>Список активных обзвонов</h3>
      <div>
        <div style="display: flex; width: 100%; flex-direction: row">
          <div style="width: 420px; border: 1px solid silver"><b>Идентификатор обзвона</b></div>
          <div style="width: 500px; border: 1px solid silver"><b>Название обзвона</b></div>
          <div style="width: 200px; border: 1px solid silver"><b>Пользователь</b></div>
          <div style="width: 180px; border: 1px solid silver"></div>
          <div style="width: 180px; border: 1px solid silver"></div>
        </div>

        <div style="width: 100%;">
          <div 
            v-for="item in list" 
            :key="item.key" 
            style="width: 100%; display: flex; flex-direction: row; height: 50px"
          >
            <div
              style="
                width: 420px; 
                border-left: 1px solid silver; 
                border-right: 1px solid silver; 
                border-bottom: 1px solid silver;
                text-align: left; 
                padding: 10px
              "
            >
              {{ item.key }}
            </div>
            <div
              style="
                width: 500px; 
                border-left: 1px solid silver; 
                border-right: 1px solid silver; 
                border-bottom: 1px solid silver; 
                text-align: left; 
                padding: 10px
              "
            >
              {{ item.name }}
            </div>
            <div
              style="
                width: 200px; 
                border-left: 1px solid silver; 
                border-right: 1px solid silver; 
                border-bottom: 1px solid silver; 
                text-align: left; 
                padding: 10px
              "
            >
              {{ item.user }}
            </div>
            <div
              style="
                width: 180px; 
                border-left: 1px solid silver; 
                border-right: 1px solid silver; 
                border-bottom: 1px solid silver
              "
            >
              <button
                style="
                  background: #f31510; 
                  padding: 8px; 
                  margin: 5px; 
                  opacity: 90%; 
                  border-radius: 5px; 
                  color: white
                "
                class="hvr"
                @click="stop(item.key, item.user)"
              >
                Остановить
              </button>
            </div>
            <div
              style="
                width: 180px; 
                border-left: 1px solid silver; 
                border-right: 1px solid silver; 
                border-bottom: 1px solid silver
              "
            >
              <button
                style="
                  background: orange; 
                  padding: 8px; 
                  margin: 5px; 
                  opacity: 90%; 
                  border-radius: 5px; 
                  color: white
                "
                class="hvr"
                @click="success(item.key, item.user)"
              >
                Завершить
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      hasStoppedCalls: false,
      stopAllDisabled: false,
      resumeAllDisabled: false
    };
  },
  methods: {
    // Подтянуть список активных (status=wait)
    getAll() {
      this.$http.post('/list_calls/all', {}, (result) => {
        this.list = result;
      });
    },
    checkStoppedCalls() {
      this.$http.post('/list_calls/checkStopped', {}, (resp) => {
        this.hasStoppedCalls = resp && resp.hasStopped;
      });
    },
    stop(call_id, user) {
      this.$http.post('/list_calls/stop', { call_id, user }, () => {
        this.getAll();
        this.checkStoppedCalls();
      });
    },
    success(call_id, user) {
      this.$http.post('/list_calls/success', { call_id, user }, () => {
        this.getAll();
        this.checkStoppedCalls();
      });
    },

    stopAll() {
      this.stopAllDisabled = true;
      setTimeout(() => {
        this.stopAllDisabled = false;
      }, 15000);

      this.$http.post('/list_calls/stopAll', {}, () => {
        this.getAll();
        this.checkStoppedCalls();
      });
    },

    resumeAll() {
      this.resumeAllDisabled = true;
      setTimeout(() => {
        this.resumeAllDisabled = false;
      }, 15000);

      this.$http.post('/list_calls/resumeAll', {}, () => {
        this.getAll();
        this.checkStoppedCalls();
      });
    }
  },
  mounted() {
    this.getAll();
    this.checkStoppedCalls();
  }
};
</script>

<style>
.hvr:hover {
  background: #bc4747 !important;
  color: black !important;
}
</style>
