/* global ym */
export default {
  install() {
    // Создаём элемент скрипта для Яндекс.Метрики
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://mc.yandex.ru/metrika/tag.js';
    document.head.appendChild(script);

    // Когда скрипт загрузится, инициализируем метрику
    script.onload = () => {
      if (typeof ym !== 'undefined') {
        ym(90339826, 'init', {
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
          webvisor: true
        });
      }
    };
  }
};
